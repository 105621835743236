@charset "UTF-8";

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

audio[controls],
canvas,
video {
  display: inline-block;
}

html {
  overflow-y: scroll;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
ul,
li,
ol,
a,
em,
img,
strong,
fieldset,
form,
label,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  list-style: none;
}

:focus {
  outline: 0;
}

img {
  vertical-align: middle;
  outline: 0;
}

button::-moz-focus-inner,
input[type=reset]::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner,
input[type=file] > input[type=button]::-moz-focus-inner {
  border: none;
}

html,
body,
textarea,
input {
  -webkit-text-size-adjust: none;
}

body {
  text-align: left;
}

button,
input[type=button],
input[type=reset],
input[type=submit] {
  cursor: pointer;
  height: 32px;
  -webkit-appearance: button;
  background: transparent;
  letter-spacing: 1px;
  border: 1px;
  text-transform: uppercase;
}

input[type=text],
input[type=email],
input[type=password],
textarea {
  background: #fff;
  outline: none;
  border: 1px solid #c9d6df;
  color: #c9d6df;
  font-size: 12px;
  height: 29px;
  padding: 0 14px;
}

input[type=text]::-webkit-input-placeholder,
input[type=email]::-webkit-input-placeholder,
input[type=password]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #c9d6df;
}

input[type=text]::-moz-placeholder,
input[type=email]::-moz-placeholder,
input[type=password]::-moz-placeholder,
textarea::-moz-placeholder {
  color: #c9d6df;
}

input[type=text]:-ms-input-placeholder,
input[type=email]:-ms-input-placeholder,
input[type=password]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #c9d6df;
}

input[type=text]:-moz-placeholder,
input[type=email]:-moz-placeholder,
input[type=password]:-moz-placeholder,
textarea:-moz-placeholder {
  color: #c9d6df;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

a {
  text-decoration: none;
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1em;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 18px;
}

h5 {
  font-size: 12px;
}

p {
  margin: 0 0 1em;
  text-align: left;
  line-height: 1.8;
}

img {
  border: 0;
}

:root {
  --white: #fff;
  --white-dark: #eeeeee;
  --black: #20172c;
  --black-dark: #191124;
  --gray: #968aa3;
  --gray-dark: #322640;
  --yellow: #ffcc00;
  --red: #ff294c;
  --green: #00ff99;
  --red-2: #ff294c;
  --bg-color: var(--black-dark);
  --font-color: var(--gray-dark);
  --decor-color: var(--red-2);
  --error-color: var(--red);
  --header-height: 70px;
}

.small-btn {
  --btn-font-color: var(--white);
  --btn-bg-color: var(--decor-color);
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  width: auto;
  height: 30px;
  font-size: 12px;
  font-weight: 700;
  color: var(--btn-font-color);
  background: var(--btn-bg-color);
  border-radius: 4px;
  cursor: pointer;
}

.small-btn__info,
.small-btn span {
  display: inline-block;
}

.small-btn__info::first-letter,
.small-btn span::first-letter {
  text-transform: capitalize;
}

.small-btn:hover {
  --btn-bg-color: #ff5e78;
}

.small-btn--black {
  --btn-font-color: var(--white);
  --btn-bg-color: var(--gray-dark);
}

.small-btn--black:hover {
  --btn-bg-color: #483959;
}

.small-btn--white {
  --btn-font-color: var(--font-color);
  --btn-bg-color: var(--white-dark);
}

.small-btn--white:hover {
  --btn-bg-color: var(--white);
}

.small-btn--green {
  --btn-font-color: var(--font-color);
  --btn-bg-color: var(--green);
}

.small-btn--green:hover {
  --btn-bg-color: #65ffc1;
}

.small-btn--big {
  width: 100%;
}

.small-btn--small {
  padding: 0;
  width: auto;
  min-width: 30px;
}

.small-btn.btn-chat {
  position: absolute;
  bottom: 0;
  right: 0;
  justify-content: flex-start;
  gap: 10px;
  min-width: 140px;
  text-transform: uppercase;
  border-radius: 4px 0 0 4px;
  box-shadow: -1px -1px 10px 0px var(--bg-color);
  z-index: 8;
}

.small-btn__img {
  display: block;
  width: 30px;
  height: 30px;
}

.small-btn--active .small-btn-st0 {
  fill: var(--decor-color);
}

.btn {
  --btn-font-color: var(--white);
  --btn-bg-color: var(--decor-color);
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  width: auto;
  height: 40px;
  font-size: 14px;
  font-weight: 700;
  color: var(--btn-font-color);
  background: var(--btn-bg-color);
  border-radius: 4px;
  cursor: pointer;
}

.btn span::first-letter {
  text-transform: capitalize;
}

.btn:hover {
  --btn-bg-color: #ff5e78;
}

.btn:disabled {
  --btn-bg-color: var(--gray);
}

.btn--black {
  --btn-font-color: var(--white);
  --btn-bg-color: var(--gray-dark);
}

.btn--black:hover {
  --btn-bg-color: #483959;
}

.btn--green {
  --btn-font-color: var(--font-color);
  --btn-bg-color: var(--green);
}

.btn--green:hover {
  --btn-bg-color: #65ffc1;
}

.btn--medium {
  min-width: 160px;
}

.btn--small {
  max-width: 90px;
  min-width: 90px;
}

.btn__img {
  display: block;
  margin-right: 10px;
  width: 30px;
  height: 30px;
}

.btn input[type=submit] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}

.nav-link {
  --nav-link-color: var(--gray);
  --nav-link-color-hover: var(--gray-dark);
  --nav-link-bg: transparent;
  --nav-link-bg-hover: var(--white-dark);
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px 0 35px;
  width: auto;
  min-width: 100px;
  height: 30px;
  font-size: 10px;
  font-weight: 400;
  color: var(--nav-link-color);
  background: var(--nav-link-bg);
  border-radius: 4px;
}

.nav-link.nav-link--dark {
  --nav-link-color: var(--nav-link-color-hover);
}

.nav-link.nav-link--dark:hover,
.nav-link.nav-link--dark.nav-link--current {
  --nav-link-color: var(--white-dark);
  --nav-link-bg: var(--gray-dark);
}

.nav-link:hover,
.nav-link--current {
  --nav-link-color: var(--font-color);
  --nav-link-bg: var(--nav-link-bg-hover);
}

.nav-link__img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 30px;
  height: 30px;
}

.nav-link__info {
  display: inline-block;
  white-space: nowrap;
  text-transform: uppercase;
}

.nav-link__alert {
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  font-size: 10px;
  font-weight: 700;
  color: var(--white);
  border-radius: 50%;
  background: var(--red);
  z-index: 2;
}

.nav-link__alert:empty {
  display: none;
  visibility: hidden;
}

.btn-close {
  --btn-font-color: var(--white-dark);
  --btn-bg-color: var(--gray-dark);
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: var(--btn-bg-color);
  border-radius: 4px;
  transition: box-shadow 0.4s ease-in-out;
  cursor: pointer;
}

.btn-close:hover {
  --btn-bg-color: var(--decor-color);
}

.btn-close__img {
  display: block;
  width: 24px;
  height: 24px;
}

.action-btn {
  --btn-font-color: var(--gray);
  --btn-bg-color: var(--gray-dark);
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  width: 30px;
  height: 30px;
  font-size: 12px;
  font-weight: 700;
  color: var(--btn-font-color);
  background: var(--btn-bg-color);
  border-radius: 4px;
  cursor: pointer;
}

.action-btn__info,
.action-btn span {
  display: inline-block;
}

.action-btn__info::first-letter,
.action-btn span::first-letter {
  text-transform: capitalize;
}

.action-btn__img,
.action-btn svg,
.action-btn img {
  display: block;
  width: 30px;
  height: 30px;
}

.action-btn .action-btn-st0 {
  fill: var(--white-dark);
}

.action-btn--active,
.action-btn:hover {
  --btn-bg-color: var(--decor-color);
}

.action-btn--favorite .action-btn-st0 {
  fill: var(--yellow);
}

.bonus-link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 50% 0 0 50%;
  cursor: pointer;
}

.bonus-link__img {
  position: relative;
  display: block;
  width: 30px;
  min-width: 30px;
  height: 30px;
  z-index: 2;
}

.bonus-link__info {
  display: none;
  visibility: hidden;
}

.btn-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  padding: 15px 0;
}

.btn-row--no-padding {
  display: inline-flex;
  flex-wrap: nowrap;
  padding: 0;
}

.form-container {
  display: block;
  width: 100%;
  max-width: 400px;
}

.form__grid {
  display: grid;
  justify-items: center;
}

.form__grid > * {
  order: 2;
}

.form__grid > *.last-order {
  order: 9;
}

.form__row {
  display: flex;
  gap: 10px;
}

.form__row > * {
  position: relative;
  flex: 1;
}

.form__info-box {
  text-align: center;
}

.input-area {
  --input-color: var(--font-color);
  --input-bg: var(--white);
  --input-decor-color: var(--font-color);
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin-top: 26px;
  max-width: 420px;
  width: 100%;
  height: 35px;
  color: var(--input-color);
  background: var(--input-bg);
  border-radius: 4px;
  border: 1px solid var(--input-decor-color);
}

.input-area--textarea {
  height: auto;
}

.input-area--true-row {
  flex-direction: row;
}

.input-area.input-area--select::before {
  content: "";
  display: block;
  height: 0;
  position: absolute;
  right: 10px;
  top: 45%;
  transition: transform 0.3s ease-in-out;
  width: 0;
  border-color: var(--font-color) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 5px 5px 0;
  z-index: 2;
}

.input-area.input-area--submit {
  display: inline-flex;
  justify-content: center;
  width: auto;
  height: auto;
  background: transparent;
  box-shadow: none;
}

.input-area.input-area--submit:hover,
.input-area.input-area--submit:focus-within,
.input-area.input-area--submit.active {
  box-shadow: none;
}

.input-area--copy {
  background: var(--input-bg) url(../assets/img/icons/icons.svg#ico_copy) right 10px center/20px 20px no-repeat;
}

.input-area--copy .input-area__placeholder {
  padding-right: 35px;
}

.input-area.input-area--completed {
  --input-decor-color: var(--gray);
  --input-bg: var(--white-dark);
  pointer-events: none;
}

.input-area.input-area--completed::after {
  opacity: 1;
}

.input-area.input-area--completed .input-drop-down::before {
  content: none;
}

.input-area.input-area--promo-cod {
  margin-left: auto;
  margin-right: auto;
}

.input-area.input-area--promo-cod::after {
  padding-left: 60px;
  opacity: 1;
}

.input-area:hover,
.input-area:focus-within,
.input-area.active {
  --input-decor-color: var(--green);
}

.input-area:hover:after,
.input-area:focus-within:after,
.input-area.active:after {
  opacity: 1;
}

.input-area:hover .input-drop-down__current,
.input-area:focus-within .input-drop-down__current,
.input-area.active .input-drop-down__current {
  opacity: 0.7;
}

.input-area:after {
  content: attr(data-label);
  display: inline-block;
  position: absolute;
  top: -1px;
  left: 0;
  transform: translateY(-100%);
  max-width: 90%;
  padding: 1px 0;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--gray);
}

.input-area.input-area--error {
  --input-decor-color: var(--red);
}

.input-area.input-area--error:after {
  content: attr(data-error);
  opacity: 1;
  color: var(--red);
}

.input-area.input-area--error input[type=email].input-area__input::-webkit-input-placeholder,
.input-area.input-area--error input[type=password].input-area__input::-webkit-input-placeholder,
.input-area.input-area--error input[type=text].input-area__input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--white);
}

.input-area.input-area--error input[type=email].input-area__input::-moz-placeholder,
.input-area.input-area--error input[type=password].input-area__input::-moz-placeholder,
.input-area.input-area--error input[type=text].input-area__input::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--white);
}

.input-area.input-area--error input[type=email].input-area__input:-ms-input-placeholder,
.input-area.input-area--error input[type=password].input-area__input:-ms-input-placeholder,
.input-area.input-area--error input[type=text].input-area__input:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--white);
}

.input-area.input-area--error input[type=email].input-area__input:-moz-placeholder,
.input-area.input-area--error input[type=password].input-area__input:-moz-placeholder,
.input-area.input-area--error input[type=text].input-area__input:-moz-placeholder {
  /* Firefox 18- */
  color: var(--white);
}

.input-area__select {
  padding: 0 10px;
  width: 70px;
  height: calc(100% - 6px);
  color: var(--font-color);
  font-size: 16px;
  text-transform: uppercase;
  background: transparent;
  border: none;
  border-left: 1px solid var(--black);
  border-radius: 0 4px 4px 0;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
}

.input-area__search-img {
  position: absolute;
  top: 51%;
  left: 5px;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  pointer-events: none;
}

.input-area__img-box {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  pointer-events: none;
}

.input-area__img-box--copy {
  pointer-events: all;
  cursor: pointer;
}

.input-area__img {
  width: 35px;
  height: 35px;
}

.input-area__placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 14px;
  font-size: 16px;
  color: var(--input-color);
  white-space: nowrap;
  background: transparent;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.input-area__placeholder--copy {
  justify-content: flex-start;
  cursor: auto;
  -webkit-user-select: all;
     -moz-user-select: all;
          user-select: all;
}

.input-area input[type=file].input-area__input,
.input-area input[type=email].input-area__input,
.input-area input[type=password].input-area__input,
.input-area input[type=text].input-area__input {
  width: 100%;
  height: 100%;
  border: none;
  background: none;
  color: inherit;
  font-size: 16px;
  flex: 1 1;
}

.input-area input[type=file].input-area__input::-webkit-input-placeholder,
.input-area input[type=email].input-area__input::-webkit-input-placeholder,
.input-area input[type=password].input-area__input::-webkit-input-placeholder,
.input-area input[type=text].input-area__input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 1;
  color: var(--input-color);
  font-weight: 400;
  text-transform: capitalize;
}

.input-area input[type=file].input-area__input::-moz-placeholder,
.input-area input[type=email].input-area__input::-moz-placeholder,
.input-area input[type=password].input-area__input::-moz-placeholder,
.input-area input[type=text].input-area__input::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 1;
  color: var(--input-color);
  font-weight: 400;
  text-transform: capitalize;
}

.input-area input[type=file].input-area__input:-ms-input-placeholder,
.input-area input[type=email].input-area__input:-ms-input-placeholder,
.input-area input[type=password].input-area__input:-ms-input-placeholder,
.input-area input[type=text].input-area__input:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 1;
  color: var(--input-color);
  font-weight: 400;
  text-transform: capitalize;
}

.input-area input[type=file].input-area__input:-moz-placeholder,
.input-area input[type=email].input-area__input:-moz-placeholder,
.input-area input[type=password].input-area__input:-moz-placeholder,
.input-area input[type=text].input-area__input:-moz-placeholder {
  /* Firefox 18- */
  opacity: 1;
  color: var(--input-color);
  font-weight: 400;
  text-transform: capitalize;
}

.input-area input[type=file].input-area__input:hover::-webkit-input-placeholder,
.input-area input[type=email].input-area__input:hover::-webkit-input-placeholder,
.input-area input[type=password].input-area__input:hover::-webkit-input-placeholder,
.input-area input[type=text].input-area__input:hover::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 0.7;
}

.input-area input[type=file].input-area__input:hover::-moz-placeholder,
.input-area input[type=email].input-area__input:hover::-moz-placeholder,
.input-area input[type=password].input-area__input:hover::-moz-placeholder,
.input-area input[type=text].input-area__input:hover::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0.7;
}

.input-area input[type=file].input-area__input:hover:-ms-input-placeholder,
.input-area input[type=email].input-area__input:hover:-ms-input-placeholder,
.input-area input[type=password].input-area__input:hover:-ms-input-placeholder,
.input-area input[type=text].input-area__input:hover:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 0.7;
}

.input-area input[type=file].input-area__input:hover:-moz-placeholder,
.input-area input[type=email].input-area__input:hover:-moz-placeholder,
.input-area input[type=password].input-area__input:hover:-moz-placeholder,
.input-area input[type=text].input-area__input:hover:-moz-placeholder {
  /* Firefox 18- */
  opacity: 0.7;
}

.input-area input[type=file].input-area__input:hover ~ .input-area__placeholder,
.input-area input[type=email].input-area__input:hover ~ .input-area__placeholder,
.input-area input[type=password].input-area__input:hover ~ .input-area__placeholder,
.input-area input[type=text].input-area__input:hover ~ .input-area__placeholder {
  opacity: 0.7;
}

.input-area input[type=file].input-area__input:hover ~ .input-area__custom-select.custom-select .custom-select__current,
.input-area input[type=email].input-area__input:hover ~ .input-area__custom-select.custom-select .custom-select__current,
.input-area input[type=password].input-area__input:hover ~ .input-area__custom-select.custom-select .custom-select__current,
.input-area input[type=text].input-area__input:hover ~ .input-area__custom-select.custom-select .custom-select__current {
  color: #222037;
  background: #ffffff;
}

.input-area input[type=file].input-area__input:focus::-webkit-input-placeholder,
.input-area input[type=email].input-area__input:focus::-webkit-input-placeholder,
.input-area input[type=password].input-area__input:focus::-webkit-input-placeholder,
.input-area input[type=text].input-area__input:focus::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 1;
}

.input-area input[type=file].input-area__input:focus::-moz-placeholder,
.input-area input[type=email].input-area__input:focus::-moz-placeholder,
.input-area input[type=password].input-area__input:focus::-moz-placeholder,
.input-area input[type=text].input-area__input:focus::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 1;
}

.input-area input[type=file].input-area__input:focus:-ms-input-placeholder,
.input-area input[type=email].input-area__input:focus:-ms-input-placeholder,
.input-area input[type=password].input-area__input:focus:-ms-input-placeholder,
.input-area input[type=text].input-area__input:focus:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 1;
}

.input-area input[type=file].input-area__input:focus:-moz-placeholder,
.input-area input[type=email].input-area__input:focus:-moz-placeholder,
.input-area input[type=password].input-area__input:focus:-moz-placeholder,
.input-area input[type=text].input-area__input:focus:-moz-placeholder {
  /* Firefox 18- */
  opacity: 1;
}

.input-area input[type=file].input-area__input:focus ~ .input-area__placeholder,
.input-area input[type=email].input-area__input:focus ~ .input-area__placeholder,
.input-area input[type=password].input-area__input:focus ~ .input-area__placeholder,
.input-area input[type=text].input-area__input:focus ~ .input-area__placeholder {
  opacity: 1;
}

.input-area input[type=file].input-area__input:focus ~ .input-area__custom-select.custom-select .custom-select__current,
.input-area input[type=email].input-area__input:focus ~ .input-area__custom-select.custom-select .custom-select__current,
.input-area input[type=password].input-area__input:focus ~ .input-area__custom-select.custom-select .custom-select__current,
.input-area input[type=text].input-area__input:focus ~ .input-area__custom-select.custom-select .custom-select__current {
  color: #222037;
  background: #ffffff;
}

.input-area input[type=submit].input-area__submit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}

.input-area input[type=submit].input-area__submit:hover ~ .btn {
  transform: translateY(5px);
}

.input-area input[type=file].input-area__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.input-area textarea {
  resize: none;
  padding: 10px 15px;
  width: 100%;
  height: 140px;
  font-size: 16px;
  color: #9c9ab3;
  background: none;
  border: none;
}

.input-area textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--input-color);
}

.input-area textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--input-color);
}

.input-area textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--input-color);
}

.input-area textarea:-moz-placeholder {
  /* Firefox 18- */
  color: var(--input-color);
}

.input-area textarea:hover::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 0.7;
}

.input-area textarea:hover::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0.7;
}

.input-area textarea:hover:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 0.7;
}

.input-area textarea:hover:-moz-placeholder {
  /* Firefox 18- */
  opacity: 0.7;
}

.input-area textarea:focus {
  color: var(--input-color);
}

.input-area textarea:focus::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 0;
}

.input-area textarea:focus::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0;
}

.input-area textarea:focus:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 0;
}

.input-area textarea:focus:-moz-placeholder {
  /* Firefox 18- */
  opacity: 0;
}

.input-drop-down {
  position: relative;
  display: block;
  width: 100%;
  font-size: 16px;
  z-index: 1;
}

.input-drop-down:before {
  content: "";
  display: block;
  position: absolute;
  top: 45%;
  right: 10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: var(--font-color) transparent transparent transparent;
  transition: transform 0.3s ease-in-out;
  z-index: 2;
}

.input-drop-down--active {
  z-index: 4;
}

.input-drop-down--active:before {
  transform: rotate(180deg);
}

.input-drop-down--active .input-drop-down__list {
  pointer-events: all;
  opacity: 1;
  transform: translateY(0);
}

.input-drop-down--small {
  width: auto;
  min-width: 105px;
}

.input-drop-down--small .input-drop-down__current {
  padding-right: 30px;
}

.input-drop-down--small .input-drop-down__info {
  padding: 3px 3px 0;
}

.input-drop-down--auto {
  width: auto;
  min-width: unset;
}

.input-drop-down--auto .input-drop-down__current {
  padding-right: 30px;
}

.input-drop-down--auto .input-drop-down__info {
  padding: 3px 3px 0;
}

.input-drop-down--line::after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 0;
  bottom: 4px;
  width: 1px;
  background: var(--gray-dark);
}

.input-drop-down__current {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 15px;
  width: 100%;
  height: 35px;
  cursor: pointer;
}

.input-drop-down__list {
  position: absolute;
  top: 46px;
  left: 0;
  width: 100%;
  max-height: 160px;
  padding: 3px 0;
  background: var(--white);
  border-radius: 5px;
  overflow-y: auto;
  box-shadow: 0 2px 5px 0 #181818;
  z-index: 3;
  pointer-events: none;
  opacity: 0;
  transform: translateY(25px);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.input-drop-down__label {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.input-drop-down__radio {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}

.input-drop-down__radio:hover ~ .input-drop-down__content,
.input-drop-down__radio:checked ~ .input-drop-down__content {
  background-color: var(--green);
}

.input-drop-down__content {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 15px;
  width: 100%;
  height: 35px;
}

.input-drop-down__img {
  display: block;
  margin-right: 5px;
  width: 24px;
  height: 18px;
}

.hide-input-area {
  padding-top: 16px;
}

.hide-input-area.hide-input-area--button .hide-input-area__trigger {
  height: 30px;
  color: var(--white);
  text-decoration: none;
  background: var(--gray);
  border-radius: 4px;
}

.hide-input-area.hide-input-area--button .input-area {
  margin-top: 10px;
}

.hide-input-area.hide-input-area--active .hide-input-area__trigger {
  display: none;
  visibility: hidden;
}

.hide-input-area.hide-input-area--active .input-area {
  display: flex;
  visibility: visible;
}

.hide-input-area__trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  font-size: 10px;
  letter-spacing: 0.1em;
  color: var(--font-color);
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
}

.hide-input-area__trigger:hover {
  text-decoration: none;
}

.hide-input-area .input-area {
  margin-top: 0;
  display: none;
  visibility: hidden;
}

.checkbox {
  position: relative;
  display: block;
  margin-top: 15px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
}

.checkbox--alert {
  padding: 5px 10px;
  background: var(--yellow);
  border-radius: 4px;
}

.checkbox input[type=checkbox] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-appearance: none;
}

.checkbox input[type=checkbox]:checked ~ .checkbox__label-content .checkbox__square:after {
  transform: translate(-50%, -50%) rotate(-45deg) scale(1);
}

.checkbox__label-content {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-align: left;
  color: var(--font-color);
}

.checkbox__square {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  min-width: 20px;
  width: 20px;
  min-height: 20px;
  height: 20px;
  background: var(--white);
  border-radius: 4px;
  border: 1px solid var(--gray);
}

.checkbox__square:after {
  content: "";
  display: block;
  position: absolute;
  top: 45%;
  left: 50%;
  width: 10px;
  height: 7px;
  border-left: 3px solid var(--gray-dark);
  border-bottom: 3px solid var(--gray-dark);
  transform: translate(-50%, -50%) rotate(0) scale(0);
  transition: transform 0.2s ease-in-out;
}

.checkbox__img {
  display: block;
  margin-right: 10px;
  width: 40px;
  min-width: 40px;
  height: 40px;
}

.radio-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.radio {
  position: relative;
  display: inline-block;
}

.radio__input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 2;
}

.radio__input:checked ~ .radio__content {
  background: var(--yellow);
  border: 1px solid var(--yellow);
}

.radio__input:checked ~ .radio__content .radio__circle::after {
  transform: scale(1);
}

.radio__content {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0 10px;
  min-width: 150px;
  width: auto;
  min-height: 40px;
  border-radius: 4px;
  border: 1px solid var(--gray);
  z-index: 1;
}

.radio__circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--white);
  border: 1px solid var(--gray);
}

.radio__circle::after {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: var(--gray-dark);
  transition: transform 0.2s ease-in-out;
  transform: scale(0);
}

.radio__info {
  padding: 0 15px;
}

.bonus-alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  padding: 0 15px;
  min-height: 20px;
  font-size: 12px;
  font-weight: 700;
  background: var(--decor-color);
  border-radius: 5px;
}

.social {
  display: block;
  padding: 5px;
  width: 100%;
}

.social__info {
  display: block;
  margin-bottom: 10px;
  font-size: 10px;
  color: var(--gray);
  text-transform: uppercase;
  text-align: center;
}

.social__list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2px;
}

.social__item {
  display: inline-block;
  width: auto;
  height: auto;
}

.social__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
}

.social__link:hover {
  transform: scale(1.2);
}

.social__img {
  display: block;
  width: 40px;
  height: 40px;
}

.select {
  position: relative;
  display: inline-block;
  height: 35px;
  min-height: 35px;
}

.select--small {
  width: 60px;
}

.select--big {
  max-width: 180px;
  width: 100%;
}

.select::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: var(--font-color) transparent transparent transparent;
  z-index: 2;
  pointer-events: none;
}

.select__select {
  display: block;
  padding: 0 20px 0 10px;
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: var(--font-color);
  background: var(--white);
  border: 1px solid var(--font-color);
  border-radius: 4px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.popup {
  --popup-bg: var(--white-dark);
  position: fixed;
  top: 20px;
  left: 50%;
  display: inline-block;
  padding: 10px;
  width: auto;
  z-index: 9;
  transform: translate(-50%, -40px);
  opacity: 0;
  pointer-events: none;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.popup--active {
  transform: translate(-50%, 0);
  opacity: 1;
  pointer-events: all;
}

.popup--dark {
  background: var(--gray-dark);
  --popup-bg: var(--black-dark);
}

.popup__body {
  position: relative;
  display: inline-flex;
  padding: 31px 0;
  width: auto;
  height: auto;
  background: var(--popup-bg);
}

.popup__close {
  position: absolute;
  top: 1px;
  right: 1px;
  z-index: 2;
}

.popup__content {
  padding: 0 20px;
  max-width: 100vw;
  min-width: 300px;
  max-height: calc(100vh - 140px);
  height: auto;
  font-size: 14px;
  overflow-x: hidden;
  overflow-y: auto;
}

.popup__content--no-padding {
  padding: 0;
}

.popup__content::-webkit-scrollbar-track {
  background: var(--white-dark);
}

.popup__content a:not(.bonus-info__link-close, .btn) {
  color: var(--decor-color);
  text-decoration: underline;
}

.popup__content a:not(.bonus-info__link-close, .btn):hover {
  text-decoration: none;
}

.popup .heading {
  --heading-bg: var(--white-dark);
}

.registration-popup {
  display: block;
  max-width: 860px;
}

.registration-popup__deposit-box {
  display: block;
  max-width: 430px;
  width: 100vw;
}

.registration-popup__deposit-box .tab__menu-btn {
  --btn-bg-color: var(--gray);
}

.registration-popup__info-box {
  padding: 0 20px 10px;
}

.registration-popup__item {
  display: flex;
  justify-content: center;
  padding: 0 20px;
  max-width: 430px;
  width: 100vw;
}

.registration-popup__item--no-padding {
  padding: 0;
}

.registration-popup__alert-box {
  position: relative;
  display: block;
  padding-top: 70px;
  width: 100%;
}

.registration-popup__alert-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
  z-index: 1;
}

.registration-popup__alert-info {
  position: relative;
  display: block;
  padding: 10px 30px;
  color: var(--white);
  text-align: center;
  background-color: rgba(23, 26, 30, 0.7);
  z-index: 2;
}

.registration-popup__alert-info > * {
  margin-bottom: 0.5em;
}

.registration-popup__form-grid {
  padding: 10px 20px;
}

.currency-current-trigger {
  position: relative;
  margin-left: auto;
  margin-right: 5px;
  cursor: pointer;
}

.currency-current-trigger::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 5px;
  width: 0px;
  height: 0px;
  transform: translateY(-50%);
  z-index: 3;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent var(--gray);
  transition: transform 0.3s ease-in-out 0s;
}

.currency-current-trigger .currency-item {
  max-width: 90px;
  background: var(--black-dark);
  border: 1px solid var(--gray-dark);
}

.currency-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  width: 122px;
  height: 42px;
  color: var(--font-color);
  background: var(--white-dark);
  border-radius: 4px;
  border: 1px solid var(--gray);
  white-space: nowrap;
  cursor: pointer;
}

.currency-item:hover,
.currency-item--active,
.currency-item--current {
  color: var(--white-dark);
  background: var(--black-dark);
  border: 1px solid var(--gray-dark);
}

.currency-item__icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  width: auto;
  height: 30px;
  border-radius: 15px;
  font-size: 18px;
  color: var(--white-dark);
  background: #262b33;
}

.currency-item__name {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.currency {
  display: block;
  max-width: 400px;
}

.currency__row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 20px;
  gap: 16px;
}

.spin-wheel {
  --deg: calc(360 / var(--items));
  --skew: calc(90 - var(--deg));
  --main-size: 290px;
}

.spin-wheel__container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--white);
}

.spin-wheel__wheel-box {
  position: relative;
}

.spin-wheel__wheel-box--hide {
  display: none;
  visibility: hidden;
}

.spin-wheel__wheel {
  position: relative;
  display: block;
  max-width: var(--main-size);
  width: 100%;
  max-height: var(--main-size);
  height: var(--main-size);
  padding: calc(var(--main-size) * 0.0138888);
  border-radius: 50%;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  overflow: hidden;
  pointer-events: none;
}

.spin-wheel__body {
  display: block;
  padding: calc(var(--main-size) * 0.0277777);
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #968aa3 0%, #20172c 100%);
  border-radius: 50%;
}

.spin-wheel__body-border {
  display: block;
  padding: calc(var(--main-size) * 0.0138888);
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #20172c 0%, #968aa3 100%);
  border-radius: 50%;
}

.spin-wheel__bonus-list {
  position: relative;
  display: block;
  width: calc(var(--main-size) * 0.88888888);
  height: calc(var(--main-size) * 0.88888888);
  border-radius: 50%;
  transform: rotate(calc(var(--deg) * -1deg));
  overflow: hidden;
}

.spin-wheel__bonus-item {
  position: absolute;
  bottom: 50%;
  right: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: calc(var(--main-size) * 0.44444444);
  height: calc(var(--main-size) * 0.44444444);
  transform-origin: 100% 100%;
  transform: rotate(calc(var(--i) * var(--deg) * 1deg)) skew(calc(var(--skew) * 1deg));
}

.spin-wheel__bonus-item:nth-child(3n-2) {
  background: linear-gradient(90deg, #20172c 0%, #362949 100%);
}

.spin-wheel__bonus-item:nth-child(3n-1) {
  background: linear-gradient(15deg, #20172c 10%, #ff294c 71.52%);
}

.spin-wheel__bonus-item:nth-child(3n) {
  background: linear-gradient(15deg, #20172c 10%, #2266ff 71.52%);
}

.spin-wheel__bonus-content {
  display: flex;
  gap: calc(var(--main-size) * 0.0305555);
  width: calc(var(--main-size) * 0.41666666);
  align-items: center;
  transform-origin: right center;
  transform: translateY(50%) skew(calc(var(--skew) * -1deg)) rotate(calc(var(--deg) / 2 * 1deg));
}

.spin-wheel__bonus-value {
  display: flex;
  justify-content: flex-end;
  width: calc(var(--main-size) * 0.080555555);
  min-width: calc(var(--main-size) * 0.080555555);
  height: calc(var(--main-size) * 0.080555555);
  min-height: calc(var(--main-size) * 0.080555555);
  color: #fff;
  font-family: Roboto Black;
  font-size: calc(var(--main-size) * 0.06944444);
  font-style: normal;
  font-weight: 900;
  text-shadow: 1px 1px 0px rgb(252, 0, 0);
  line-height: normal;
}

.spin-wheel__bonus-info {
  color: #bfb6cc;
  font-family: Inter;
  font-size: calc(var(--main-size) * 0.0333333333);
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  line-height: normal;
  text-shadow: 1px 1px 0px rgba(252, 0, 0, 0.5);
  white-space: nowrap;
}

.spin-wheel__arrow {
  position: absolute;
  bottom: 50%;
  right: 50%;
  display: block;
  width: calc(var(--main-size) * 0.5);
  height: calc(var(--main-size) * 0.5);
  transform-origin: 100% 100%;
  transform: rotate(calc(var(--deg) * -0.5deg)) skew(calc(var(--skew) * 1deg));
  z-index: 2;
}

.spin-wheel__arrow-body {
  position: relative;
  display: block;
  width: inherit;
  height: inherit;
  overflow: hidden;
}

.spin-wheel__arrow-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: calc(var(--main-size) * 0.0138888);
  background-color: var(--white);
  transform-origin: 100% 100%;
  transform: skew(calc(var(--skew) * -1deg));
}

.spin-wheel__arrow-right {
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  width: calc(var(--main-size) * 0.0138888);
  height: 100%;
  background-color: var(--white);
  transform-origin: 100% 100%;
  transform: skew(calc(var(--skew) * -1deg)) rotate(calc(var(--skew) * -1deg));
}

.spin-wheel__circle-border {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: var(--main-size);
  height: var(--main-size);
  border-radius: 50%;
  border: calc(var(--main-size) * 0.055555555) solid var(--white);
  transform: skew(calc(var(--skew) * -1deg));
}

.spin-wheel__triangle {
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  width: calc(var(--main-size) * 0.5);
  height: 1px;
  transform-origin: 100% 50%;
  transform: skew(calc(var(--skew) * -1deg)) rotate(calc(var(--deg) * 0.5deg));
}

.spin-wheel__triangle-body {
  position: relative;
  width: calc(var(--main-size) * 0.5);
  height: 1px;
}

.spin-wheel__triangle-top {
  position: absolute;
  top: 0;
  left: calc(var(--main-size) * 0.0111111111);
  transform: translateY(-50%);
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: calc(var(--main-size) * 0.043055555) 0 calc(var(--main-size) * 0.043055555) calc(var(--main-size) * 0.0527777778);
  border-color: transparent transparent transparent #ff294c;
  z-index: 2;
}

.spin-wheel__triangle-bottom {
  position: absolute;
  top: 0;
  left: calc(var(--main-size) * 0.0111111111);
  transform: translateY(-50%);
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: calc(var(--main-size) * 0.0583333333) 0 calc(var(--main-size) * 0.0583333333) calc(var(--main-size) * 0.0625);
  border-color: transparent transparent transparent var(--white);
  z-index: 1;
}

.spin-wheel__center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: calc(var(--main-size) * 0.0694444444);
  height: calc(var(--main-size) * 0.0694444444);
  border-radius: 50%;
  background: #322640;
  border: calc(var(--main-size) * 0.0138888) solid var(--white);
  z-index: 3;
}

.spin-wheel__btn-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.2);
  transition: transform 0.3s ease-in-out;
  z-index: 4;
}

.spin-wheel__btn-box--hide {
  transform: translate(-50%, -50%) scale(0);
  pointer-events: none;
}

.spin-wheel__action-box {
  transition: transform 0.3s ease-in-out;
}

.spin-wheel__action-box--hide {
  transform: scale(0);
  pointer-events: none;
}

.spin-wheel__info-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-top: 10px;
  width: 100%;
}

.spin-wheel__info-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background: var(--gray-dark);
}

.spin-wheel__info-item > * {
  flex: 1;
}

.spin-wheel__info-title {
  color: var(--gray);
  white-space: nowrap;
}

.spin-wheel__info-value {
  font-weight: 700;
  letter-spacing: 0.05em;
  color: var(--white);
  white-space: nowrap;
}

.spin-wheel__action-btn {
  --bg-color: #231930;
  --font-color: var(--gray);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 30px;
  width: 30px;
  min-width: 30px;
  height: 30px;
  font-size: 20px;
  font-weight: 700;
  color: var(--font-color);
  background-color: var(--bg-color);
  border-radius: 50%;
  cursor: pointer;
}

.spin-wheel__action-btn:hover {
  --bg-color: var(--gray);
  --font-color: var(--gray-dark);
}

.spin-wheel__players-list {
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  color: var(--font-color);
  background: var(--white-dark);
  overflow-y: auto;
  transform: scale(0);
  pointer-events: none;
  transition: transform 0.2s ease-in-out;
  z-index: 4;
}

.spin-wheel__players-list--active {
  transform: scale(1);
  pointer-events: all;
}

.spin-wheel__players-table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}

.spin-wheel__players-table tr:nth-child(odd) {
  background: var(--white);
}

.spin-wheel__players-table td {
  height: 40px;
}

.spin-wheel__question {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  padding: 30px;
  color: var(--font-color);
  text-transform: uppercase;
  background: var(--white-dark);
  transform: scale(0);
  pointer-events: none;
  transition: transform 0.2s ease-in-out;
  z-index: 4;
}

.spin-wheel__question--active {
  transform: scale(1);
  pointer-events: all;
}

.spin-wheel__question-row {
  display: flex;
  gap: 30px;
}

.spin-wheel__deposit-info {
  position: absolute;
  top: 2px;
  left: 0;
  right: 0;
  display: block;
  padding: 10px;
  color: var(--font-color);
  text-transform: uppercase;
  background: var(--white-dark);
  transform: scale(0);
  pointer-events: none;
  transition: transform 0.2s ease-in-out;
  z-index: 4;
}

.spin-wheel__deposit-info--active {
  transform: scale(1);
  pointer-events: all;
}

.spin-wheel__deposit-box {
  display: block;
  padding: 20px;
  text-align: center;
}

.spin-wheel__winner-box {
  display: none;
  visibility: hidden;
  text-align: center;
}

.spin-wheel__winner-box--active {
  display: block;
  visibility: visible;
}

.spin-wheel__winner-info {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  padding: 20px 10px 10px;
  width: 100%;
  border: 5px solid var(--decor-color);
}

.spin-wheel__winner-img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 90px;
  height: 90px;
  transform: translate(-10%, -70%);
}

.spin-wheel__winner-info-txt {
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
}

.spin-wheel .rotate-scale-up {
  -webkit-animation: rotate-scale-up 0.65s linear both;
  animation: rotate-scale-up 0.65s linear both;
}

@-webkit-keyframes rotate-scale-up {
  0% {
    transform: scale(1) rotateZ(0);
  }

  50% {
    transform: scale(3) rotateZ(180deg);
  }

  100% {
    transform: scale(2) rotateZ(360deg);
  }
}

@keyframes rotate-scale-up {
  0% {
    transform: scale(1) rotateZ(0);
  }

  50% {
    transform: scale(3) rotateZ(180deg);
  }

  100% {
    transform: scale(2) rotateZ(360deg);
  }
}

.spin-btn {
  display: none;
  visibility: hidden;
}

.hero {
  position: fixed;
  left: 10px;
  bottom: 0;
  display: block;
  z-index: 8;
}

.hero__link {
  cursor: pointer;
}

.hero__img {
  display: block;
  width: 90px;
  height: 90px;
  -webkit-animation: heroBounce 5s linear infinite;
          animation: heroBounce 5s linear infinite;
}

@-webkit-keyframes heroBounce {
  from, 20%, 53%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0) scaleY(1.1);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0) scaleY(1.05);
  }

  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0) scaleY(0.95);
  }

  90% {
    transform: translate3d(0, -4px, 0) scaleY(1.02);
  }
}

@keyframes heroBounce {
  from, 20%, 53%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0) scaleY(1.1);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0) scaleY(1.05);
  }

  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0) scaleY(0.95);
  }

  90% {
    transform: translate3d(0, -4px, 0) scaleY(1.02);
  }
}

.action-area {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  background: linear-gradient(0deg, var(--black-dark) 25%, rgba(238, 130, 238, 0) 100%);
  z-index: 4;
}

.action-area > * + * {
  margin-left: 10px;
}

.header {
  position: relative;
}

.header__fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--header-height);
  z-index: 8;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0px 1px 10px 0px var(--bg-color);
}

.header__fixed--hide {
  transform: translateY(-120%);
}

.header__top {
  --header-top-bg: var(--black);
  position: relative;
  display: block;
  width: 100%;
  height: 40px;
  background: var(--header-top-bg);
}

.header__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: 40px;
}

.header__item {
  flex-basis: 50%;
  display: flex;
  align-items: center;
  gap: 5px;
}

.header__item--left {
  justify-content: flex-start;
  padding-right: 5px;
}

.header__item--right {
  justify-content: flex-end;
  padding-left: 5px;
}

.header__center {
  --header-center-bg: var(--white-dark);
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 30px;
  background: var(--header-center-bg);
}

.header .lang {
  display: none;
  visibility: hidden;
}

.header .main-nav {
  display: none;
  visibility: hidden;
}

.header .user__short-info {
  display: none;
  visibility: hidden;
}

.header .user__img-area {
  margin-left: 0;
}

.header .user__img-box,
.header .user__img {
  cursor: pointer;
}

.header .small-btn--black {
  --btn-font-color: var(--white);
}

.hamburger {
  --hamburger-bg: var(--gray-dark);
  --line-bg: var(--white-dark);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 4px;
  background: var(--hamburger-bg);
  cursor: pointer;
}

.hamburger:hover {
  --hamburger-bg: #483959;
}

.hamburger--active .hamburger__line--top {
  transform: translateX(3px) rotate(45deg);
}

.hamburger--active .hamburger__line--center {
  transform: scaleX(0);
}

.hamburger--active .hamburger__line--bottom {
  transform: translate(1px, 1px) rotate(-45deg);
}

.hamburger__line {
  display: block;
  width: 16px;
  height: 2px;
  background: var(--line-bg);
  transition: transform 0.2s ease-in-out;
}

.hamburger__line--top {
  transform-origin: top left;
}

.hamburger__line--center {
  margin: 3px 0;
}

.hamburger__line--bottom {
  transform-origin: top left;
}

.logo {
  --logo-font-color: var(--white-dark);
  --logo-bg: var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 5px;
}

.logo__img {
  display: block;
  width: 55px;
  height: 30px;
}

.logo__info {
  font-size: 12px;
  font-weight: 700;
  color: var(--logo-font-color);
  text-transform: uppercase;
}

.search-widget-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  min-width: 30px;
  height: 30px;
  background: var(--gray-dark);
  border-radius: 50%;
  cursor: pointer;
}

.search-widget-trigger:hover {
  background: #483959;
}

.search-widget-trigger__img {
  display: block;
  width: 24px;
  height: 24px;
}

.search-widget-trigger__info {
  display: none;
  visibility: hidden;
}

.search-widget-trigger__info:empty {
  display: none;
  visibility: hidden;
}

.search-area {
  --search-bg: var(--white-dark);
  --search-active-color: var(--decor-color);
  --search-input-color: var(--font-color);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 220px;
  width: 100%;
  height: 30px;
  background: var(--search-bg);
  border-radius: 4px;
  border: 1px solid var(--search-bg);
}

.search-area:focus-within {
  border-color: var(--search-active-color);
}

.search-area input.search-area__input {
  display: block;
  padding-right: 30px;
  width: 100%;
  height: 100%;
  font-style: 16px;
  font-weight: 700;
  color: var(--search-input-color);
  background: transparent;
  border: none;
  border-radius: inherit;
}

.search-area input.search-area__input:focus::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 0.6;
}

.search-area input.search-area__input:focus::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0.6;
}

.search-area input.search-area__input:focus:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 0.6;
}

.search-area input.search-area__input:focus:-moz-placeholder {
  /* Firefox 18- */
  opacity: 0.6;
}

.search-area input.search-area__input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: inherit;
}

.search-area input.search-area__input::-moz-placeholder {
  /* Firefox 19+ */
  color: inherit;
}

.search-area input.search-area__input:-ms-input-placeholder {
  /* IE 10+ */
  color: inherit;
}

.search-area input.search-area__input:-moz-placeholder {
  /* Firefox 18- */
  color: inherit;
}

.search-area__img {
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
  display: block;
  width: 18px;
  height: 18px;
  z-index: 2;
}

.registration__row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.lang {
  --lang-bg: var(--gray-dark);
  --lang-current-bg: var(--gray-dark);
  --lang-list-bg: var(--gray-dark);
  --lang-font-color: var(--gray);
  --lang-link-current-bg: var(--white);
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.lang--active .lang__list {
  pointer-events: all;
  opacity: 1;
  transform: translateY(3px);
}

.lang__current {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: var(--lang-current-bg);
  border-radius: 50%;
  cursor: pointer;
}

.lang__current .lang__img {
  width: 30px;
  height: 30px;
}

.lang__list {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 180px;
  border-radius: 5px;
  background: var(--lang-list-bg);
  transform: translateY(25px);
  z-index: 3;
  pointer-events: none;
  opacity: 0;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.lang__item {
  order: 2;
  margin-bottom: 3px;
}

.lang__item--current {
  order: 1;
}

.lang__link {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  font-size: 12px;
  color: var(--lang-font-color);
  text-transform: uppercase;
  border-radius: 4px;
}

.lang__link--current {
  --lang-font-color: var(--white);
}

.lang__link:hover {
  --lang-font-color: var(--black);
  background: var(--lang-link-current-bg);
}

.lang__img {
  display: block;
  width: 40px;
  height: 40px;
}

.lang__info {
  padding: 0 5px;
}

.login__row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.login__row .bonus-link {
  display: none;
  visibility: hidden;
}

.user {
  --user-color: var(--white-dark);
  --shadow-color: var(--bg-color);
  --user-decor-color: var(--white);
  --percent-bg: var(--gray-dark);
  --short-info-bg: var(--black-dark);
  --short-border-color: var(--gray-dark);
  position: relative;
  display: block;
  max-width: 340px;
  width: 100%;
  color: var(--user-color);
}

.user__header {
  display: flex;
  align-items: center;
}

.user__short-info {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 15px 4px 5px;
  min-height: 32px;
  max-height: 42px;
  background: var(--short-info-bg);
  border-radius: 4px;
  border: 1px solid var(--short-border-color);
  white-space: nowrap;
  cursor: pointer;
}

.user__short-info-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  width: auto;
  height: 20px;
  border-radius: 15px;
  font-size: 16px;
  color: var(--white-dark);
  background: #262b33;
}

.user__value-side {
  padding: 0 10px;
  font-weight: 700;
  text-align: right;
}

.user__value-item {
  font-size: 14px;
}

.user__value-item--bonus {
  color: var(--decor-color);
  font-size: 12px;
}

.user__value-item--bonus sup {
  font-size: 0.4em;
}

.user__value-item sup {
  font-size: 0.4em;
}

.user__value-item-icon {
  display: inline-block;
  margin-bottom: -2px;
  margin-right: 10px;
  width: 14px;
  height: 14px;
}

.user__type-side {
  position: relative;
  padding: 0 10px;
  font-size: 10px;
  line-height: 16px;
  color: var(--gray);
  text-transform: uppercase;
}

.user__type-side::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%);
  display: block;
  width: 1px;
  height: 100%;
  background: var(--decor-color);
}

.user__img-area {
  display: flex;
  align-items: center;
  margin-left: -10px;
}

.user__img-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 4px;
  background: var(--short-border-color);
  border: 1px solid var(--short-border-color);
}

.user__img {
  display: block;
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 4px;
  -o-object-fit: cover;
     object-fit: cover;
}

.user__status-box {
  display: block;
  padding: 0 10px;
  max-width: 100px;
  width: 100%;
  text-align: right;
}

.user__name {
  display: block;
  max-width: 100px;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: var(--user-decor-color);
  overflow: hidden;
  text-overflow: ellipsis;
}

.user__status {
  display: block;
  max-width: 70px;
  width: 100%;
  font-size: 10px;
  color: var(--gray);
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user__progress {
  position: relative;
  display: flex;
  margin: 4px -10px 0;
}

.user__progress-percent {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  height: 20px;
  font-size: 12px;
  font-weight: 700;
  padding: 0 3px;
}

.user__progress-box {
  flex: 1;
  display: block;
  padding: 2px 1px;
  height: 20px;
  background: var(--black-dark);
  border: 1px solid var(--gray-dark);
}

.user__progress-line {
  display: block;
  height: 100%;
  width: attr(data-width);
  background: var(--decor-color);
}

.user__alert {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -1px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  font-size: 10px;
  line-height: 11px;
  font-weight: 700;
  background-color: var(--red);
  border-radius: 50%;
  z-index: 3;
}

.mobile-menu {
  --action-area-bg: var(--black);
  --nav-bg: var(--gray-dark);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: grid;
  grid-template-rows: 40px 1fr;
  max-width: 360px;
  width: 100vw;
  z-index: 9;
  pointer-events: none;
  transform: translateX(-120%);
  transition: transform 0.3s ease-in-out;
}

.mobile-menu--active {
  pointer-events: all;
  transform: translateX(0);
}

.mobile-menu__action-area {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  background: var(--action-area-bg);
}

.mobile-menu__action-area .small-btn--black {
  --btn-font-color: var(--white);
}

.mobile-menu__log-reg-area {
  flex: 1;
}

.mobile-menu__nav {
  display: block;
  padding: 0 0 60px;
  background: var(--nav-bg);
  overflow-y: auto;
}

.mobile-menu__row {
  display: flex;
}

.mobile-menu__list {
  --list-bg: var(--black);
  flex: 1;
  padding: 10px;
  background: var(--list-bg);
}

.mobile-menu__list--white {
  --list-bg: var(--white-dark);
}

.mobile-menu__list--transparent {
  --list-bg: transparent;
}

.mobile-menu__item {
  position: relative;
  margin-bottom: 5px;
}

.mobile-menu__item:last-child {
  margin-bottom: 0;
}

.mobile-menu__item--sub-menu::after {
  content: "";
  display: block;
  position: absolute;
  top: 14px;
  right: 16px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: var(--gray) transparent transparent transparent;
  pointer-events: none;
  transition: transform 0.2s ease-in-out;
}

.mobile-menu__item--active::after {
  transform: rotate(180deg);
}

.mobile-menu .registration-link {
  display: none;
  visibility: hidden;
}

.sub-menu {
  --sub-menu-bg: var(--white);
  --sub-menu-link-hover-bg: var(--white-dark);
  --sub-menu-link-color: var(--gray);
  --sub-menu-link-color-hover: var(--gray-dark);
  display: none;
  visibility: hidden;
  padding: 10px 20px 0;
}

.sub-menu--active {
  display: block;
  visibility: visible;
}

.sub-menu__item {
  margin-bottom: 3px;
}

.sub-menu__link {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 40px;
  padding-right: 10px;
  width: 100%;
  height: 30px;
  font-size: 10px;
  color: var(--sub-menu-link-color);
  border-radius: 4px;
  cursor: pointer;
}

.sub-menu__link--current,
.sub-menu__link:hover {
  background: var(--sub-menu-link-hover-bg);
  color: var(--sub-menu-link-color-hover);
}

.sub-menu__link-info {
  display: inline-block;
  text-transform: uppercase;
}

.mobile-lang {
  --mobile-lang-bg: transparent;
  --lang-font-color: var(--gray);
  --lang-link-bg-hover: var(--white-dark);
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0 30px;
  height: 41px;
  background: var(--mobile-lang-bg);
  border-top: 1px solid var(--black);
  overflow: hidden;
}

.mobile-lang::after {
  content: "";
  display: block;
  position: absolute;
  top: 22px;
  right: 26px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: var(--gray) transparent transparent transparent;
  pointer-events: none;
  transition: transform 0.2s ease-in-out;
}

.mobile-lang--active {
  height: auto;
}

.mobile-lang--active::after {
  transform: rotate(180deg);
}

.mobile-lang--active .mobile-lang__link--current {
  --lang-font-color: var(--white);
}

.mobile-lang__item {
  order: 2;
  margin-bottom: 8px;
}

.mobile-lang__item--current {
  --lang-font-color: var(--white);
  order: 1;
}

.mobile-lang__item:last-child {
  margin-bottom: 0;
}

.mobile-lang__link {
  display: flex;
  align-items: center;
  padding: 0 5px;
  width: 100%;
  height: 30px;
  font-size: 10px;
  font-weight: 400;
  color: var(--lang-font-color);
  text-transform: capitalize;
  border-radius: 4px;
  cursor: pointer;
}

.mobile-lang__link:hover {
  --lang-font-color: var(--black);
  background: var(--lang-link-bg-hover);
}

.mobile-lang__img {
  display: block;
  width: 30px;
  height: 30px;
}

.mobile-lang__info {
  text-transform: uppercase;
  padding: 0 15px;
}

.search-widget {
  --search-widget-bg: var(--black);
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: 40px 1fr;
  max-width: 360px;
  width: 100%;
  height: 100vh;
  z-index: 9;
  transform: translateX(-100%);
  transition: transform 0.2s ease-in-out;
}

.search-widget--active {
  transform: translateX(0);
}

.search-widget__action-area {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  height: 40px;
  background: var(--search-widget-bg);
}

.search-widget__result-area {
  display: block;
  padding: 10px 10px 60px;
  width: 100%;
  max-height: calc(100vh - 40px);
  background: var(--white-dark);
  overflow-y: auto;
  visibility: visible;
}

.search-widget__result-area:empty {
  display: none;
  visibility: hidden;
}

.search-widget .search-area {
  max-width: 100%;
}

.search-result__item {
  display: flex;
  margin-bottom: 10px;
}

.search-result__img-link {
  display: block;
  width: 105px;
  min-width: 105px;
}

.search-result__content-box {
  flex: 1;
}

.search-result__content-top {
  display: block;
  padding: 10px;
  background: var(--white);
}

.search-result__content-top:hover {
  background: var(--yellow);
}

.search-result__content-top:hover .search-result__sub-title {
  color: var(--font-color);
}

.search-result__content-bottom {
  display: block;
  padding: 0 10px;
}

.search-result__row {
  display: flex;
  justify-content: flex-end;
}

.search-result__row-item {
  flex-basis: 50%;
}

.search-result__row-item--center {
  display: flex;
  justify-content: center;
}

.search-result__title {
  display: block;
  max-width: 94px;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  color: var(--gray-dark);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-result__sub-title {
  display: block;
  max-width: 94px;
  width: 100%;
  font-size: 10px;
  color: var(--gray);
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-result__btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  height: 20px;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: var(--white);
  text-transform: uppercase;
  background: var(--gray-dark);
  border-radius: 0 0 4px 4px;
}

.search-result .game-item__img-box:after {
  font-size: 16px;
}

.fast-dep {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fast-dep__title {
  display: block;
  margin-bottom: 10px;
  padding: 0 20px;
  max-width: 500px;
  width: 100%;
  font-weight: 700;
  text-align: center;
}

.fast-dep__form {
  display: inline-block;
  max-width: 340px;
  width: 100%;
  padding: 20px;
  background: var(--white-dark);
}

.user-menu {
  --action-area-bg: var(--black);
  --nav-bg: var(--white-dark);
  --link-bg: transparent;
  --link-color: var(--gray-dark);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-rows: 40px 1fr 70px;
  max-width: 360px;
  width: 100vw;
  z-index: 9;
  pointer-events: none;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.user-menu--active {
  pointer-events: all;
  transform: translateX(0);
}

.user-menu__action-area {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  background: var(--action-area-bg);
}

.user-menu__action-area .small-btn--black {
  --btn-font-color: var(--white);
}

.user-menu__deposit-link {
  flex: 1;
}

.user-menu__nav {
  display: block;
  padding: 15px 20px 30px;
  background: var(--nav-bg);
  overflow-y: auto;
}

.user-menu__row {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.user-menu__list {
  flex: 1;
}

.user-menu__item {
  position: relative;
  margin-bottom: 10px;
}

.user-menu__item--sub-menu::after {
  content: "";
  display: block;
  position: absolute;
  top: 20px;
  right: 16px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: var(--font-color) transparent transparent transparent;
  pointer-events: none;
  transition: transform 0.2s ease-in-out;
}

.user-menu__item--active::after {
  transform: rotate(180deg);
}

.user-menu__link {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px 0 30px;
  width: 100%;
  height: 30px;
  font-size: 10px;
  color: var(--link-color);
  background: var(--link-bg);
  border-radius: 4px;
}

.user-menu__link:hover {
  --link-color: var(--white);
  --link-bg: var(--gray);
}

.user-menu__link--current {
  --link-color: var(--white);
  --link-bg: var(--gray-dark);
}

.user-menu__link-info {
  text-transform: uppercase;
}

.user-menu__link-alert {
  position: absolute;
  top: 2px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  font-size: 10px;
  font-weight: 700;
  color: var(--white);
  border-radius: 50%;
  background: var(--red);
  z-index: 2;
}

.user-menu__link-alert:empty {
  display: none;
  visibility: hidden;
}

.user-menu__footer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0 10px;
  width: 100%;
  height: 70px;
  background: var(--action-area-bg);
}

.user-menu .user__short-info {
  height: 42px;
}

.user-menu .user__value-item {
  font-size: 13px;
}

.user-menu .user__img-box {
  width: 42px;
  min-width: 42px;
  height: 42px;
}

.user-menu .user__img {
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.user-menu .user__status-box {
  padding: 0;
}

.main-nav {
  --main-nav-font-color: var(--gray);
  --main-nav-link-bg: transparent;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
}

.main-nav__list {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0 5px;
  width: auto;
  overflow-y: hidden;
  overflow-x: auto;
}

.main-nav__item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1px;
}

.main-nav__link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 14px;
  width: auto;
  min-width: 50px;
  height: 20px;
  font-size: 10px;
  font-weight: 700;
  color: var(--main-nav-font-color);
  background: var(--main-nav-link-bg);
  border-radius: 4px;
}

.main-nav__link:hover {
  --main-nav-font-color: var(--white);
  --main-nav-link-bg: var(--gray-dark);
}

.main-nav__link--active {
  --main-nav-font-color: var(--gray-dark);
  --main-nav-link-bg: var(--white-dark);
}

.main-nav__link--active:hover {
  --main-nav-font-color: var(--gray-dark);
  --main-nav-link-bg: var(--white-dark);
}

.main-nav__link--row-reverse {
  flex-direction: row-reverse;
  padding: 0 0 0 10px;
}

.main-nav__link--column {
  flex-direction: column;
  padding: 0 0 10px 0;
}

.main-nav__link--column-revers {
  flex-direction: column-reverse;
  padding: 10px 0 0 0;
}

.main-nav__img {
  display: none;
  visibility: hidden;
}

.main-nav__info {
  display: inline-block;
  text-transform: uppercase;
  white-space: nowrap;
}

.main-slider {
  --info-bg: var(--white);
  --info-border: var(--white-dark);
}

.main-slider__slider {
  perspective: 1000px;
  background: var(--decor-color-2);
}

.main-slider__slider .main-slider__img-link {
  transform: rotateY(90deg);
  transition: transform 0.6s ease-in-out;
}

.main-slider__slider .main-slider__info-box {
  transform: translateY(100%);
  transition: transform 0.6s ease-in-out;
}

.main-slider__slider .slick-slide.slick-current .main-slider__img-link {
  transform: rotateY(0);
  transition-delay: 0.3s;
}

.main-slider__slider .slick-slide.slick-current .main-slider__info-box {
  transform: translateY(0);
  transition-delay: 0.3s;
}

.main-slider__item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

.main-slider__item-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 1;
}

.main-slider__img-side {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  width: 100%;
  z-index: 2;
}

.main-slider__img-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.main-slider__img {
  display: block;
  max-width: 600px;
  width: 100%;
  max-height: 300px;
  height: auto;
}

.main-slider__info-side {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -25px;
  padding: 0 17px;
  max-width: 600px;
  width: 100%;
  z-index: 3;
}

.main-slider__info-box {
  flex: 1;
  position: relative;
  display: block;
  padding: 35px 5px;
  max-width: 600px;
  width: 100%;
  font-size: 24px;
  color: var(--font-color);
  text-align: center;
  background: var(--info-bg);
  border-radius: 35px;
  border: 10px solid var(--info-border);
  box-shadow: 0px 0px 0px 7px var(--info-bg);
}

.main-slider__info--big {
  font-size: 3.333em;
  line-height: 1;
  font-weight: 700;
}

.main-slider__btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
  margin-bottom: -70px;
}

.them-filter {
  --them-filter-bg: var(--white-dark);
  --link-color: var(--black);
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  width: 100%;
  background: var(--them-filter-bg);
}

.them-filter__list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  padding: 0 3px;
  text-align: center;
  overflow-y: hidden;
  overflow-x: auto;
}

.them-filter__item {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 3px;
}

.them-filter__link {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.them-filter__link:hover .them-filter__link-info {
  color: var(--white);
  background: var(--gray);
}

.them-filter__link--active .them-filter__link-info {
  color: var(--white);
  background: var(--gray-dark);
  box-shadow: none;
}

.them-filter__link--active:hover .them-filter__link-info {
  color: var(--white);
  background: var(--gray-dark);
  box-shadow: none;
}

.them-filter__link--sub-menu .them-filter__link-info {
  position: relative;
  padding-right: 25px;
}

.them-filter__link--sub-menu .them-filter__link-info::before,
.them-filter__link--sub-menu .them-filter__link-info::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
}

.them-filter__link--sub-menu .them-filter__link-info::before {
  right: 6px;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  background: var(--gray-dark);
  border-radius: 3px;
  z-index: 1;
}

.them-filter__link--sub-menu .them-filter__link-info::after {
  right: 9px;
  transform: translateY(-25%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 3px 0 3px;
  border-color: var(--white) transparent transparent transparent;
  z-index: 2;
}

.them-filter__link--sub-menu:hover .them-filter__link-info,
.them-filter__link--sub-menu.them-filter__link--active .them-filter__link-info {
  padding-right: 25px;
}

.them-filter__link--sub-menu.them-filter__link--active .them-filter__link-info::before {
  background: var(--red-2);
}

.them-filter__link-info {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 3px 15px;
  height: 20px;
  font-size: 10px;
  font-weight: 700;
  color: var(--link-color);
  text-transform: uppercase;
  white-space: nowrap;
  border-radius: 4px;
  background: transparent;
}

.them-filter__sub-menu {
  --sub-menu-bg: var(--black-dark);
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: calc(100vh - 100px);
  height: auto;
  display: none;
  visibility: hidden;
  background: var(--sub-menu-bg);
  overflow-y: auto;
}

.them-filter__sub-menu--active {
  display: block;
  visibility: visible;
}

.them-filter__catalog {
  display: none;
  visibility: hidden;
}

.them-filter__catalog--active {
  display: block;
  visibility: visible;
}

.them-catalog {
  display: block;
  padding: 10px 0 20px;
  width: 100%;
}

.them-catalog__slider {
  display: none;
  visibility: hidden;
}

.them-catalog__slider.slick-initialized {
  display: block;
  visibility: visible;
}

.them-catalog__slider .slick-list {
  padding: 0 30px;
  -webkit-mask-image: linear-gradient(90deg, hsla(0deg, 0%, 100%, 0), #fff 3%, #fff 97%, hsla(0deg, 0%, 100%, 0));
          mask-image: linear-gradient(90deg, hsla(0deg, 0%, 100%, 0), #fff 3%, #fff 97%, hsla(0deg, 0%, 100%, 0));
}

.them-catalog__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 110px);
  justify-content: center;
  gap: 10px 0;
}

.them-catalog__list--scroll {
  justify-content: start;
  grid-template-columns: repeat(10, 130px);
  grid-template-rows: 120px 120px;
  grid-auto-flow: column;
  overflow-x: auto;
  -webkit-mask-image: linear-gradient(90deg, #fff, #fff 3%, #fff 97%, hsla(0deg, 0%, 100%, 0));
  mask-image: linear-gradient(90deg, #fff, #fff 3%, #fff 97%, hsla(0deg, 0%, 100%, 0));
}

.them-catalog__item {
  display: inline-flex;
  padding: 0 10px;
}

.them-catalog__link {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 5px 0px 5px;
  width: 90px;
  background: var(--black);
  border-radius: 4px;
}

.them-catalog__link:hover,
.them-catalog__link--active {
  background: var(--white-dark);
}

.them-catalog__link:hover .them-catalog__link-info,
.them-catalog__link--active .them-catalog__link-info {
  color: var(--font-color);
}

.them-catalog__link-img {
  display: block;
  width: 80px;
  height: 60px;
  -o-object-fit: cover;
     object-fit: cover;
}

.them-catalog__link-info {
  display: inline-flex;
  align-items: center;
  width: 80px;
  height: 25px;
  font-size: 10px;
  font-weight: 700;
  color: var(--gray);
  text-transform: uppercase;
  text-align: center;
  overflow: hidden;
}

.them-catalog__link-info span {
  display: block;
  width: 80px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.provider-catalog {
  --link-img-bg: var(--black);
  display: block;
}

.provider-catalog__grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 10px;
  padding: 20px 0;
  width: 100%;
}

.provider-catalog__slider {
  display: none;
  visibility: hidden;
}

.provider-catalog__slider.slick-initialized {
  display: block;
  visibility: visible;
}

.provider-catalog__slider .slick-list {
  padding: 0 30px;
  -webkit-mask-image: linear-gradient(90deg, hsla(0deg, 0%, 100%, 0), #fff 3%, #fff 97%, hsla(0deg, 0%, 100%, 0));
          mask-image: linear-gradient(90deg, hsla(0deg, 0%, 100%, 0), #fff 3%, #fff 97%, hsla(0deg, 0%, 100%, 0));
}

.provider-catalog__slider .provider-catalog__item {
  padding-left: 5px;
  padding-right: 5px;
}

.provider-catalog__item {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
}

.provider-catalog__link {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 0 5px;
  width: auto;
  height: 40px;
  background: var(--black);
  border-radius: 4px;
  cursor: pointer;
}

.provider-catalog__link:hover,
.provider-catalog__link--active {
  background: var(--white-dark);
}

.provider-catalog__link:hover .provider-catalog__link-info,
.provider-catalog__link--active .provider-catalog__link-info {
  color: var(--font-color);
}

.provider-catalog__link-img-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.provider-catalog__link-img {
  display: block;
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
     object-fit: cover;
}

.provider-catalog__link-info {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  width: auto;
  font-size: 10px;
  color: var(--gray);
  text-transform: uppercase;
}

.provider-catalog__link-count {
  position: absolute;
  top: -3px;
  left: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 3px;
  width: auto;
  min-width: 20px;
  height: 12px;
  font-size: 10px;
  color: var(--gray);
  background: var(--gray-dark);
  border-radius: 3px;
  z-index: 2;
}

.game-item {
  position: relative;
  display: inline-block;
  max-width: 300px;
  width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.game-item__widget-trigger {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
}

.game-item__box {
  position: relative;
  display: block;
  width: 100%;
  z-index: 1;
}

.game-item__label {
  position: absolute;
  bottom: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  width: auto;
  height: 14px;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background: var(--gray-dark);
  border-radius: 4px;
  z-index: 4;
  pointer-events: none;
}

.game-item__label--new {
  right: 0;
  transform: translate(4px, 4px);
  color: var(--green);
}

.game-item__label--jackpot {
  left: 50%;
  transform: translate(-50%, 4px);
  color: var(--yellow);
}

.game-item__label--hot {
  left: 0;
  transform: translate(-4px, 4px);
  color: var(--red);
}

.game-item__label:empty {
  display: none;
  visibility: hidden;
}

.game-item__img-box {
  position: relative;
  width: 100%;
  background: var(--gray-dark);
}

.game-item__img-box:after {
  content: attr(data-title);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: var(--gray);
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  z-index: 1;
  pointer-events: none;
}

.game-item__img-container {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 66%;
}

.game-item__game-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.game-item__btn-box {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(33, 33, 33, 0.8);
  z-index: 3;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

.game-item__btn-row {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.game-item__btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 30px;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: var(--gray);
  background: var(--gray-dark);
  cursor: pointer;
}

.game-item__btn--left {
  left: 0;
  border-radius: 0 4px 4px 0;
}

.game-item__btn--right {
  right: 0;
  border-radius: 4px 0 0 4px;
}

.game-item__btn .small-btn-st0 {
  fill: var(--gray);
}

.game-item__btn:hover,
.game-item__btn--active {
  color: var(--white);
  background: var(--decor-color);
}

.game-item__btn:hover .small-btn-st0,
.game-item__btn--active .small-btn-st0 {
  fill: var(--white);
}

.game-item__btn--favorite {
  background: var(--gray-dark);
}

.game-item__btn--favorite .small-btn-st0 {
  fill: var(--yellow);
}

.game-item__btn--favorite:hover {
  background: var(--decor-color);
}

.game-item__btn--favorite:hover .small-btn-st0 {
  fill: var(--yellow);
}

.game-item__jackpot {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  z-index: 4;
}

.game-item__jackpot-info {
  --jackpot-font-color: var(--font-color);
  --jackpot-bg-color: var(--yellow);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 7px;
  width: auto;
  min-width: 130px;
  height: 20px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: var(--jackpot-font-color);
  background: var(--jackpot-bg-color);
  border-radius: 4px;
}

.game-item__title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--white);
}

.game-item .btn {
  letter-spacing: 0.04em;
}

.play-game {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - var(--header-height));
}

.play-game--full-screen {
  margin-top: calc(var(--header-height) * -1);
  height: 100vh;
  z-index: 10;
}

.play-game--full-screen .play-game__box {
  width: 100%;
  height: 100%;
}

.play-game__img-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
}

.play-game__box {
  position: relative;
  display: block;
  width: calc((100vh - 150px) * 1.77);
  height: calc(100vh - 120px);
  z-index: 2;
}

.play-game__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  height: 40px;
  background-color: var(--black);
}

.play-game__title {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--gray);
}

.play-game__title-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.play-game__action-box {
  display: flex;
  align-items: center;
  gap: 5px;
}

.play-game__game-box {
  position: relative;
  display: block;
  width: 100%;
  height: calc(100% - 40px);
  background: var(--black-dark);
}

.play-game__game-box > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.play-game__mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.play-game__mobile-btn-box {
  display: block;
  margin: 0 auto;
  padding-top: 15px;
  max-width: 300px;
  width: 100%;
}

.play-game__mobile-btn-row {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px 0;
}

.play-game__btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.play-game__btn--left {
  left: 0;
}

.play-game__btn--right {
  right: 0;
}

.play-game .game-item__box {
  display: flex;
  flex-direction: column-reverse;
}

.play-game .game-item__title {
  font-size: 14px;
}

.win-now {
  --min-width: 220px;
  --bg-color: var(--white-dark);
  display: block;
  overflow: hidden;
  background: var(--bg-color);
}

.win-now__row {
  display: flex;
  -webkit-mask-image: linear-gradient(90deg, #fff, #fff 3%, #fff 97%, hsla(0deg, 0%, 100%, 0));
          mask-image: linear-gradient(90deg, #fff, #fff 3%, #fff 97%, hsla(0deg, 0%, 100%, 0));
}

.win-now__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: var(--min-width);
  min-width: var(--min-width);
  font-size: 10px;
  letter-spacing: 0.05em;
  color: var(--font-color);
  text-align: center;
}

.win-now__value {
  --win-value-font-color: var(--white);
  --win-value-bg-color: var(--decor-color);
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  padding: 0 7px;
  width: auto;
  min-width: 100px;
  height: 20px;
  font-size: 12px;
  font-weight: 700;
  color: var(--win-value-font-color);
  background: var(--win-value-bg-color);
  border-radius: 4px;
  z-index: 2;
}

.win-now__player {
  display: flex;
  justify-content: center;
  align-items: center;
}

.win-now__game-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  color: inherit;
  text-transform: uppercase;
}

.win-now__game-link span {
  display: inline-block;
}

.win-now__game-link span::first-letter {
  text-transform: capitalize;
}

.win-now__game-link:hover {
  color: var(--decor-color);
}

.win-now .game-item {
  z-index: 1;
}

.win-now .game-item__label,
.win-now .game-item__btn,
.win-now .game-item__title {
  display: none;
  visibility: hidden;
}

.promo-slider {
  display: none;
  visibility: hidden;
  margin: 0 -5px;
}

.promo-slider.slick-initialized {
  display: block;
  visibility: visible;
}

.bonus-card {
  --bg-color: var(--white-dark);
  display: block;
  padding: 0 5px;
  max-width: 310px;
  width: 100%;
  color: var(--font-color);
  text-align: center;
}

.bonus-card--shop .countdown {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bonus-card--shop .bonus-card__content {
  padding-top: 40px;
  z-index: 2;
}

.bonus-card--shop .bonus-card__info-box {
  align-content: start;
}

.bonus-card__img-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 67.74193%;
  width: 300px;
  height: 0;
}

.bonus-card__img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.bonus-card__content {
  position: relative;
  display: grid;
  grid-template-rows: 1fr 40px;
  padding: 20px 15px;
  min-height: 210px;
  background: var(--bg-color);
  z-index: 2;
}

.bonus-card__info-box {
  display: grid;
  justify-content: center;
  align-content: center;
  gap: 20px;
  height: 100%;
  font-size: 12px;
}

.bonus-card__info {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 5em;
}

.bonus-card__info a {
  color: var(--decor-color);
  text-decoration: underline;
}

.bonus-card__info a:hover {
  text-decoration: none;
}

.bonus-card__info--big {
  font-size: 1.33333em;
  line-height: 1;
  font-weight: 700;
}

.bonus-card__btn-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.bonus-info {
  display: block;
  max-width: 640px;
  width: 100%;
  line-height: 1.667;
}

.bonus-info--small {
  max-width: 380px;
}

.bonus-info__content {
  display: block;
  margin: 0 auto;
  max-width: 640px;
  width: 100%;
}

.bonus-info__img-box {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
}

.bonus-info__img {
  display: block;
  max-width: 640px;
  width: calc(100% + 30px);
}

.bonus-info__title-box {
  display: block;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
  color: var(--white-dark);
  text-align: center;
  background: var(--gray-dark);
}

.bonus-info__title--big {
  font-size: 2.5em;
}

.bonus-info__info {
  display: block;
  margin-bottom: 2em;
}

.bonus-info__info a {
  color: var(--decor-color);
  text-decoration: underline;
}

.bonus-info__info a:hover {
  text-decoration: none;
}

.bonus-info__proposition-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 10px 0;
}

.bonus-info__proposition-img {
  display: block;
  width: 80px;
  height: 80px;
}

.bonus-info__proposition-title {
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.bonus-info__proposition-limit-row {
  position: relative;
  display: flex;
  gap: 15px;
  margin-bottom: -10px;
  padding: 0 20px;
  height: 20px;
  color: var(--white);
  text-transform: uppercase;
  background: var(--decor-color);
  z-index: 2;
}

.bonus-info__proposition {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 20px 15px;
  width: 100%;
  font-size: 24px;
  text-align: center;
  line-height: 1;
  font-weight: 700;
  color: var(--white);
  background: var(--gray-dark);
}

.bonus-info__table {
  margin-bottom: 25px;
}

.bonus-info__rules {
  counter-reset: item;
}

.bonus-info__rules li {
  margin-bottom: 1em;
}

.bonus-info__rules li::before {
  counter-increment: item;
  content: counter(item) ". ";
  display: inline-block;
  margin-right: 5px;
  color: inherit;
  font-weight: 700;
}

.bonus-info__rules a {
  color: var(--decor-color);
  text-decoration: underline;
}

.bonus-info__rules a:hover {
  text-decoration: none;
}

.bonus-info__link-close {
  font-size: 12px;
  color: var(--font-color);
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: underline;
}

.bonus-info__link-close:hover {
  text-decoration: none;
}

.bonus-page {
  margin-bottom: 45px;
}

.bonus-page__grid {
  --min-width: 320px;
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(var(--min-width), 1fr));
  gap: 10px 0;
  padding-bottom: 25px;
}

.bonus-page .tab__menu {
  gap: 15px;
}

.bonus-page .tab__menu-item {
  flex-grow: unset;
}

.bonus-page .tab__menu-item .h2 {
  padding-right: 0;
}

.tournament-card {
  --bg-color: var(--white-dark);
  display: block;
  padding: 0 5px;
  max-width: 310px;
  width: 100%;
  color: var(--font-color);
  text-align: center;
}

.tournament-card--vertical {
  padding: 0;
  max-width: 460px;
}

.tournament-card--vertical .tournament-card__img-box {
  width: 100%;
}

.tournament-card--vertical .tournament-card__content {
  position: relative;
  padding-top: 0;
  min-height: unset;
  z-index: 2;
}

.tournament-card--vertical .tournament-card__info-box {
  margin-bottom: 20px;
}

.tournament-card--vertical .countdown {
  margin-top: -25px;
}

.tournament-card__img-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 67.74193%;
  width: 300px;
  height: 0;
}

.tournament-card__img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.tournament-card__content {
  display: grid;
  grid-template-rows: 1fr 40px;
  padding: 20px 15px;
  min-height: 210px;
  background: var(--bg-color);
}

.tournament-card__info-box {
  display: grid;
  justify-content: center;
  align-content: center;
  gap: 20px;
  font-size: 12px;
}

.tournament-card__info {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 5em;
}

.tournament-card__info--big {
  font-size: 1.33333em;
  line-height: 1;
  font-weight: 700;
}

.tournament-card__btn-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.countdown {
  --bg-color: var(--gray-dark);
  --font-color: var(--white-dark);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 0 15px;
  width: auto;
  color: var(--font-color);
  background: var(--bg-color);
  border-radius: 4px;
}

.countdown__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 30px;
  height: 55px;
}

.countdown__item::after {
  content: ":";
  position: absolute;
  top: 6px;
  right: 0;
  transform: translate(13px, 0);
  font-size: 18px;
  font-weight: 700;
}

.countdown__item:first-child::after,
.countdown__item:last-child::after {
  content: none;
}

.countdown__count {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  height: 30px;
  font-size: 18px;
  line-height: 1;
}

.countdown__name {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  font-size: 10px;
  text-transform: uppercase;
  color: var(--gray);
  border: 3px solid transparent;
  border-top-color: var(--decor-color);
}

.payment-system {
  display: block;
}

.payment-system__slider {
  margin: 0 -5px;
  display: none;
  visibility: hidden;
}

.payment-system__slider.slick-initialized {
  display: block;
  visibility: visible;
}

.payment-system__slider .slick-list {
  -webkit-mask-image: linear-gradient(90deg, hsla(0deg, 0%, 100%, 0), #fff 3%, #fff 97%, hsla(0deg, 0%, 100%, 0));
          mask-image: linear-gradient(90deg, hsla(0deg, 0%, 100%, 0), #fff 3%, #fff 97%, hsla(0deg, 0%, 100%, 0));
}

.payment-system__row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.payment-system__item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
}

.payment-system__link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.payment-system__link:hover .payment-system__img-box {
  background: var(--white-dark);
}

.payment-system__img-box {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  background: var(--black);
  border-radius: 4px;
}

.payment-system__img {
  display: block;
  max-width: 90px;
  width: 100%;
  max-height: 34px;
  height: auto;
}

.testimonials__slider {
  margin: 0 -5px;
  display: none;
  visibility: hidden;
}

.testimonials__slider.slick-initialized {
  display: block;
  visibility: visible;
}

.testimonials__item {
  display: inline-block;
  padding: 0 5px;
  width: 310px;
  overflow: hidden;
}

.testimonials__info-box {
  display: block;
  padding: 15px;
  width: 100%;
  height: 70px;
  font-size: 12px;
  color: var(--gray);
  text-align: center;
  background: var(--black-dark);
  border: 2px solid var(--gray-dark);
}

.testimonials__info {
  position: relative;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 42px;
  font-size: 12px;
}

.testimonials__info::before {
  content: "«";
}

.testimonials__info::after {
  content: "»";
}

.testimonials__author-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  font-size: 10px;
  color: var(--gray);
  background: var(--black);
}

.testimonials__img {
  display: block;
  width: auto;
  max-height: 30px;
  height: auto;
  filter: grayscale(1);
}

.testimonials__author-name {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.testimonials__author-country {
  display: inline-block;
  line-height: 0.8em;
}

.testimonials__author-country::first-letter {
  text-transform: uppercase;
}

.e-sports__slider {
  display: none;
  visibility: hidden;
  margin: 0 -5px;
}

.e-sports__slider.slick-initialized {
  display: block;
  visibility: visible;
}

.e-sports__slider .slick-list {
  -webkit-mask-image: linear-gradient(90deg, #fff, #fff 2%, #fff 98%, hsla(0deg, 0%, 100%, 0));
          mask-image: linear-gradient(90deg, #fff, #fff 2%, #fff 98%, hsla(0deg, 0%, 100%, 0));
}

.e-sports__item {
  display: block;
  padding: 0 5px;
  width: 170px;
}

.e-sports__img-box {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 150%;
  overflow: hidden;
}

.e-sports__img-box:hover .e-sports__img {
  transform: scale(1.1);
}

.e-sports__img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: transform 0.6s ease-in-out;
}

.e-sports__info-box {
  display: block;
  padding: 10px;
  width: 100%;
  height: 120px;
  font-size: 12px;
  color: var(--font-color);
  text-align: center;
  background: var(--white-dark);
}

.e-sports__event {
  display: block;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-sports__time {
  position: relative;
  display: block;
  margin-top: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

.e-sports__time--live::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-320%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--red);
}

.e-sports__date {
  font-size: 10px;
  line-height: 0.8;
}

.e-sports__date:empty {
  display: none;
  visibility: hidden;
}

.betting-category {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.betting-category__list {
  display: flex;
  align-items: center;
  padding: 5px 15px;
}

.betting-category__item {
  display: inline-block;
  padding: 0 5px;
}

.betting-category__item:first-child {
  padding-left: 0;
}

.betting-category__link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: 30px;
  height: 30px;
  font-size: 12px;
  font-weight: 700;
  color: var(--font-color);
  border-radius: 4px;
  background: var(--gray-dark);
  overflow: hidden;
}

.betting-category__link:hover,
.betting-category__link--active {
  background: var(--white);
}

.betting-category__link--active {
  padding: 0 8px;
}

.betting-category__link--active .betting-category__link-info-box {
  width: auto;
}

.betting-category__link--active .betting-category__link-info {
  transform: translateY(0);
}

.betting-category__img {
  display: block;
  width: 24px;
  height: 24px;
}

.betting-category__link-info-box {
  display: block;
  width: 0;
}

.betting-category__link-info {
  display: inline-block;
  padding: 0 10px;
  white-space: nowrap;
  transform: translateY(200%);
  transition: transform 0.2s ease-in-out;
}

.betting-category__link-info::first-letter {
  text-transform: capitalize;
}

.betting-event__slider {
  display: none;
  visibility: hidden;
  margin: 0 -5px;
}

.betting-event__slider.slick-initialized {
  display: block;
  visibility: visible;
}

.betting-event__slider .slick-list {
  -webkit-mask-image: linear-gradient(90deg, #fff, #fff 2%, #fff 97%, hsla(0deg, 0%, 100%, 0));
          mask-image: linear-gradient(90deg, #fff, #fff 2%, #fff 97%, hsla(0deg, 0%, 100%, 0));
}

.betting-event__item {
  display: block;
  padding: 0 5px;
  width: 260px;
  color: var(--font-color);
  text-align: center;
  overflow: hidden;
}

.betting-event__info-area {
  background: var(--white-dark);
}

.betting-event__title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  height: 25px;
}

.betting-event__row {
  display: grid;
  grid-template-columns: 38% 24% 38%;
}

.betting-event__player {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.betting-event__player-img-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: var(--white);
  border-radius: 4px;
}

.betting-event__player-img {
  display: block;
  max-width: 40px;
  max-height: 50px;
  height: auto;
}

.betting-event__player-name {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  font-size: 10px;
  text-transform: uppercase;
}

.betting-event__game-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
}

.betting-event__game-time {
  font-size: 14px;
  font-weight: 700;
}

.betting-event__game-date {
  font-size: 10px;
}

.betting-event__link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  font-size: 10px;
  line-height: 11px;
  color: var(--white-dark);
  text-transform: uppercase;
  background: var(--gray-dark);
}

.betting-event__link:hover {
  background: var(--decor-color);
}

.betting-event__link:hover .betting-event__link-frame {
  color: var(--white-dark);
  background: var(--decor-color);
}

.betting-event__link b {
  font-size: 14px;
}

.betting-event__link-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20px;
  color: var(--font-color);
  background: var(--white-dark);
  border-radius: 2px;
}

.news__slider {
  margin: 0 -5px;
  display: none;
  visibility: hidden;
}

.news__slider.slick-initialized {
  display: block;
  visibility: visible;
}

.news__item {
  display: block;
  padding: 0 5px;
  width: 310px;
  overflow: hidden;
}

.news__img-box {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 61.3%;
  background-color: var(--gray);
  overflow: hidden;
}

.news__img-box:hover .news__img {
  transform: scale(1.05);
}

.news__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

.news__info-box {
  display: block;
  padding: 10px 20px;
  width: 100%;
  height: 110px;
  color: var(--font-color);
  background: var(--white-dark);
}

.news__title-link {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
  width: 100%;
  height: 34px;
  font-size: 14px;
  font-weight: 700;
  color: var(--font-color);
  overflow: hidden;
}

.news__title-link:hover {
  color: var(--decor-color);
}

.news__info {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 42px;
  font-size: 12px;
}

.tournament {
  display: block;
  margin: 0 -10px 20px;
  padding: 20px 20px 40px;
  line-height: 1.667;
  color: var(--font-color);
  background: var(--white-dark);
}

.tournament__grid {
  display: grid;
  justify-items: center;
  margin-bottom: 15px;
}

.tournament__table-box {
  display: block;
  margin-bottom: 25px;
  width: 100%;
}

.tournament__table-prev-link {
  display: inline-block;
  font-size: 12px;
  letter-spacing: 0.1em;
  color: var(--font-color);
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
}

.tournament__table-prev-link::after {
  content: "";
  display: inline-block;
  margin-left: 0.5em;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 4px;
  border-color: transparent transparent transparent var(--decor-color);
  transition: transform 0.2s ease-in-out;
}

.tournament__table-prev-link:hover {
  text-decoration: none;
  color: var(--decor-color);
}

.tournament__table-prev-link:hover::after {
  transform: translateX(0.2em);
}

.tournament__info-main {
  margin-bottom: 1em;
  font-size: 14px;
  font-weight: 700;
}

.tournament__table {
  margin-bottom: 20px;
}

.tournament__table td,
.tournament__table th {
  padding: 5px;
}

.tournament .h1 {
  margin-bottom: 0.8em;
  text-align: center;
}

.payment-catalog {
  display: block;
  width: 100%;
  background: var(--gray-dark);
}

.payment-catalog__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(106px, 1fr));
  justify-items: center;
  justify-content: center;
  padding: 10px;
}

.payment-card {
  order: 2;
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 3px;
  max-width: 160px;
  width: 100%;
  min-width: 106px;
}

.payment-card:hover .payment-card__title-img-box {
  background: var(--white);
}

.payment-card--form .payment-card__title-img-box,
.payment-card--active .payment-card__title-img-box {
  background: var(--white);
}

.payment-card--form {
  max-width: 240px;
}

.payment-card--form .payment-card__title-img-box {
  height: 60px;
}

.payment-card--form .payment-card__title-img {
  display: block;
  max-width: 170px;
  width: 100%;
  max-height: 54px;
  height: auto;
}

.payment-card__radio-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
}

.payment-card__radio-input:hover ~ .payment-card__body .payment-card__title-img-box {
  background: var(--white);
}

.payment-card__radio-input:checked ~ .payment-card__body .payment-card__title-img-box {
  background: var(--white);
}

.payment-card__body {
  position: relative;
  z-index: 1;
}

.payment-card__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.payment-card__title-img-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100px;
  height: 40px;
  background: var(--black);
  border-radius: 4px;
}

.payment-card__title-img {
  display: block;
  max-width: 90px;
  width: 100%;
  max-height: 34px;
  height: auto;
}

.payment-card__btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px 0;
}

.rules {
  --rules-bg: var(--white-dark);
  display: flex;
  justify-content: center;
  margin: 0 -10px;
  padding: 20px 10px;
  line-height: 1.6667;
  color: var(--font-color);
  background: var(--rules-bg);
}

.rules--big-font {
  font-size: 16px;
}

.rules--black {
  color: var(--gray);
  background: var(--black-dark);
  border: 2px solid var(--gray-dark);
}

.rules__body {
  display: block;
  width: 100%;
}

.rules__inner {
  display: block;
  margin: 0 auto;
  max-width: 860px;
  width: 100%;
}

.rules__inner--big {
  max-width: 1200px;
}

.rules__nav {
  position: relative;
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 30px;
  color: var(--decor-color);
  z-index: 2;
}

.rules__nav li {
  position: relative;
}

.rules__nav li a {
  color: inherit;
  text-decoration: underline;
}

.rules__nav li a:hover {
  text-decoration: none;
}

.rules__info {
  margin-bottom: 0.5em;
}

.rules__info--faq {
  padding-left: 10px;
}

.rules__info h3,
.rules__info .h3 {
  margin-bottom: 0;
}

.rules__info p {
  line-height: inherit;
}

.rules__info a {
  color: var(--decor-color);
  text-decoration: underline;
}

.rules__info a:hover {
  text-decoration: none;
}

.rules__content {
  position: relative;
  z-index: 1;
}

.rules__content > li {
  position: relative;
  margin-top: calc((var(--header-height) + 16px) * -1);
  padding-top: calc(var(--header-height) + 16px);
}

.rules__content > li::before {
  position: absolute;
  top: calc(var(--header-height) + 16px);
  left: 0;
}

.rules__content > li h3,
.rules__content > li .h3 {
  text-indent: 23px;
}

.rules__content li {
  margin-bottom: 1em;
  z-index: 1;
}

.rules__content li ol {
  margin-top: 1em;
}

.rules__content li:hover {
  z-index: 2;
}

.rules__content a {
  color: var(--decor-color);
  text-decoration: underline;
}

.rules__content a:hover {
  text-decoration: none;
}

.rules h3,
.rules .h3 {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 700;
}

.rules h3::first-letter,
.rules .h3::first-letter {
  text-transform: capitalize;
}

.rules h3 + ol,
.rules .h3 + ol {
  margin-top: 0;
}

.rules ol {
  counter-reset: item;
}

.rules ol > li::before {
  counter-increment: item;
  content: counter(item) ". ";
  display: inline-block;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 700;
  color: var(--font-color);
}

.rules ol > li ol li::before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}

.rules ol > li ul {
  padding-left: 10px;
}

.rules ol > li ul li {
  margin-bottom: 0;
}

.rules ol > li ul li::before {
  content: "";
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 1px;
  width: 5px;
  height: 5px;
  background: var(--decor-color);
  border-radius: 50%;
}

.rules p + ul {
  margin-top: -1em;
}

.rules ul {
  padding-left: 10px;
}

.rules ul li {
  margin-bottom: 0;
}

.rules ul li::before {
  content: "";
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 1px;
  width: 5px;
  height: 5px;
  background: var(--decor-color);
  border-radius: 50%;
}

.account__grid {
  display: block;
  margin: 0 -10px;
}

.account__bonus-grid {
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, 320px);
  gap: 10px 0;
  padding: 0 5px 20px;
  width: 100%;
}

.account__row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.account__body {
  display: block;
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
  color: var(--font-color);
  background: var(--white-dark);
}

.account__body--active {
  box-shadow: inset 0px 0px 0px 10px var(--yellow);
}

.account__body--transparent {
  background: transparent;
}

.account__shape {
  display: block;
  max-width: 420px;
  width: 100%;
  color: var(--font-color);
}

.account__shape--medium {
  max-width: 860px;
}

.account__shape--large {
  max-width: 1260px;
}

.account__shape {
  margin: 0 auto;
}

.account__messaage {
  position: relative;
  display: block;
  margin-bottom: 20px;
  padding: 10px 10px 20px;
  line-height: 1.6667;
  background: var(--white-dark);
}

.account__messaage--new {
  background: var(--white);
}

.account__messaage--new .account__messaage-date {
  color: var(--red);
}

.account__messaage a {
  color: var(--decor-color);
  text-decoration: underline;
}

.account__messaage a:hover {
  text-decoration: none;
}

.account__messaage-close {
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 2;
}

.account__messaage-date {
  display: block;
  margin-bottom: 5px;
  padding-right: 20px;
  color: var(--gray);
}

.account__img-box {
  position: relative;
  display: flex;
  justify-content: center;
}

.account__img-box--active .account__img-gallery {
  transform: translate(-50%, 0);
  opacity: 1;
  pointer-events: all;
}

.account__img-gallery-body {
  position: relative;
  display: inline-block;
  padding: 25px 0 15px;
  background-color: var(--gray-dark);
  border-radius: 4px;
}

.account__img-current,
.account__img-item {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
}

.account__img-current {
  --bg-color: var(--gray-dark);
  position: relative;
  padding-right: 20px;
  width: 91px;
  min-width: 91px;
  height: 72px;
  background: var(--bg-color);
  border: 1px solid var(--bg-color);
}

.account__img-current:hover {
  --bg-color: var(--decor-color);
}

.account__img-current:before {
  content: "";
  display: block;
  position: absolute;
  top: 45%;
  right: 4px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: var(--white) transparent transparent transparent;
  transition: transform 0.3s ease-in-out;
  z-index: 1;
}

.account__img-item {
  --border-color: var(--white);
  width: 70px;
  min-width: 70px;
  height: 70px;
}

.account__img-item--current,
.account__img-item:hover {
  border: 2px solid var(--border-color);
}

.account__img {
  display: block;
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 4px;
  -o-object-fit: cover;
     object-fit: cover;
}

.account__img-gallery {
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translate(-50%, 20%);
  box-shadow: 0 4px 10px 0 rgba(21, 21, 21, 0.1);
  z-index: 2;
  pointer-events: none;
  opacity: 0;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.account__img-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  min-width: 180px;
  max-height: 310px;
  overflow-x: hidden;
  overflow-y: auto;
}

.account__img-gallery-close {
  position: absolute;
  top: -10px;
  right: -10px;
}

.account__favorite-game {
  position: relative;
  display: block;
  max-width: 300px;
  width: 100%;
}

.account__favorite-remove {
  position: absolute;
  top: -5px;
  right: -4px;
  z-index: 5;
}

.account__filter-box .input-area {
  margin-top: 0;
  max-width: 180px;
}

.account__filter-box .input-area::after {
  content: none;
}

.account__filter-box .btn {
  height: 35px;
}

.account__filter-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.account__filter-item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.account__form-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.account__form-row .btn {
  margin-top: 26px;
}

.account__form-grid {
  display: grid;
}

.account__form-grid > * {
  order: 9;
}

.footer {
  --footer-padding-bottom: 100px;
  --footer-bg: var(--bg-color);
  --footer-color: var(--white-dark);
  display: block;
  padding: 25px 0 var(--footer-padding-bottom);
  color: var(--footer-color);
  background: var(--footer-bg);
}

.footer__nav {
  display: none;
  visibility: hidden;
}

.footer__contacts {
  display: block;
}

.footer__partners {
  display: block;
}

.footer__info-box {
  display: block;
  padding: 15px 0;
  font-size: 10px;
  line-height: 14px;
  color: var(--gray);
  text-align: center;
  border-top: 1px solid var(--gray-dark);
  border-bottom: 1px solid var(--gray-dark);
}

.footer__service-box {
  display: block;
}

.footer-nav {
  --footer-nav-color: var(--gray);
  --footer-nav-bg: var(--black-dark);
  display: block;
  padding: 10px 0;
  background: var(--footer-nav-bg);
}

.footer-nav__list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px 0;
}

.footer-nav__item {
  position: relative;
  padding: 0 15px;
}

.footer-nav__item::after {
  content: "|";
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 12px;
  font-weight: 700;
  color: var(--gray-dark);
}

.footer-nav__item:last-child::after {
  content: none;
}

.footer-nav__link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0 7px;
  min-height: 20px;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--footer-nav-color);
  border-radius: 4px;
}

.footer-nav__link:hover {
  color: var(--white);
  background-color: var(--gray-dark);
}

.footer-nav__link--current {
  color: var(--font-color);
  background-color: var(--white-dark);
}

.footer-nav__link-img:empty {
  display: none;
  visibility: hidden;
}

.footer-nav__link-info:empty {
  display: none;
  visibility: hidden;
}

.footer-nav__link-info::first-letter {
  text-transform: capitalize;
}

.footer-contacts {
  --footer-contacts-link-color: var(--gray);
  display: block;
  padding: 20px 0;
  border-top: 1px solid var(--gray-dark);
  border-bottom: 1px solid var(--gray-dark);
}

.footer-contacts__list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.footer-contacts__link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0 10px;
  font-size: 12px;
  color: var(--footer-contacts-link-color);
}

.footer-contacts__link:hover {
  color: var(--decor-color);
}

.footer-contacts__link:hover .footer-contacts__link-img {
  opacity: 1;
}

.footer-contacts__link-img {
  display: block;
  max-width: 30px;
  width: 30px;
  height: auto;
  opacity: 0.6;
}

.footer-contacts__link-info:empty {
  display: none;
  visibility: hidden;
}

.footer-partners {
  display: block;
  padding: 10px 0;
}

.footer-partners__slider {
  display: none;
  visibility: hidden;
}

.footer-partners__slider.slick-initialized {
  display: block;
  visibility: visible;
}

.footer-partners__slider .slick-list {
  -webkit-mask-image: linear-gradient(90deg, #fff, #fff 2%, #fff 97%, hsla(0deg, 0%, 100%, 0));
  mask-image: linear-gradient(90deg, #fff, #fff 2%, #fff 97%, hsla(0deg, 0%, 100%, 0));
}

.footer-partners__item {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
}

.footer-partners__link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  filter: grayscale(1);
}

.footer-partners__link:hover {
  filter: grayscale(0);
}

.footer-partners__link-img {
  display: block;
  width: auto;
  height: 30px;
}

.footer-partners__link-info:empty {
  display: none;
  visibility: hidden;
}

.footer-service {
  display: block;
  padding: 15px 0;
}

.footer-service__list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.footer-service__link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.footer-service__link-img {
  display: block;
  max-height: 36px;
  width: auto;
}

.footer-service__link-info:empty {
  display: none;
  visibility: hidden;
}

.footer-rights {
  display: block;
  text-align: center;
  padding: 10px;
  font-size: 12px;
  color: var(--gray);
}

.footer-rights__row {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.footer-rights__logo {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  gap: 5px;
}

.footer-rights__img {
  display: inline-block;
  height: 16px;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto Condensed", sans-serif;
}

/* width / height */

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */

::-webkit-scrollbar-track {
  background: var(--gray-dark);
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: var(--gray);
}

body {
  position: relative;
  padding-top: var(--header-height);
  font-size: 12px;
  font-weight: 400;
  color: var(--font-color);
  background: var(--bg-color);
  overflow-x: hidden;
}

body.on-line .registration {
  display: none;
  visibility: hidden;
}

body.on-line .login {
  display: block;
  visibility: visible;
}

body.on-line .action-area {
  display: none;
  visibility: hidden;
}

body .registration {
  display: block;
  visibility: visible;
}

body .login {
  display: none;
  visibility: hidden;
}

.dn {
  display: none !important;
  visibility: hidden !important;
}

.disabled {
  opacity: 0.6 !important;
  pointer-events: none !important;
  filter: grayscale(1) !important;
}

.decor-color {
  color: var(--decor-color);
}

.decor-bg {
  background: var(--decor-color);
}

.grayscale {
  filter: grayscale(1);
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 14px;
  font-weight: 700;
  color: inherit;
  text-transform: uppercase;
}

h3,
.h3 {
  font-size: 14px;
  font-weight: 700;
}

h4,
.h4 {
  font-size: 12px;
}

.ttu {
  text-transform: uppercase;
}

.small {
  font-size: 0.86em;
}

b,
strong,
.bold {
  font-weight: 700;
}

hr {
  display: block;
  width: 100%;
  height: 1px;
  background: var(--gray-dark);
  border: none;
}

.overlay {
  --overlay-bg: var(--gray-dark);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--overlay-bg);
  opacity: 0;
  z-index: 9;
  transform: translateY(-40%);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  pointer-events: none;
}

.overlay--active {
  pointer-events: all;
  opacity: 0.8;
  transform: translateY(0);
}

.wrapper {
  display: block;
  margin: 0 auto;
  padding: 0 10px;
  max-width: 1920px;
  width: 100%;
}

.row {
  display: flex;
  width: 100%;
}

.row--between {
  justify-content: space-between;
}

main {
  min-height: 100vh;
}

.slick-prev,
.slick-next {
  --arrow-color: var(--white);
  --triangle-color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: var(--arrow-color);
  border-radius: 50%;
  opacity: 0.8;
  z-index: 3;
}

.slick-prev::before,
.slick-next::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 30px;
  height: 30px;
  font-size: 0;
  background: url(../assets/img/icons/icons.svg#ico_arrow) center center/20px 20px no-repeat;
  opacity: 1;
}

.slick-prev:focus,
.slick-prev:hover,
.slick-next:focus,
.slick-next:hover {
  background: var(--arrow-color);
  opacity: 1;
}

.slick-prev {
  left: 0px;
}

.slick-prev::before {
  transform: translate(-50%, -50%) scale(-1);
}

.slick-next {
  right: 0;
}

.section {
  --section-color: var(--white);
  position: relative;
  display: block;
  padding: 15px 0;
  color: var(--section-color);
}

.section--fast-dep {
  background: transparent url(../assets/img/main-slider/main-slide-bg.png) center bottom/cover no-repeat;
}

.heading {
  --heading-color: var(--white);
  --heading-bg: var(--gray-dark);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}

.heading--center {
  justify-content: center;
}

.heading--between {
  justify-content: space-between;
}

.heading__line {
  flex: 1;
  display: block;
  width: 100%;
  height: 1px;
  background: var(--heading-bg);
}

.heading__content * {
  margin-bottom: 0;
  white-space: nowrap;
}

.heading__link {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  font-size: 14px;
  font-weight: 700;
  color: var(--heading-color);
  text-transform: uppercase;
  white-space: nowrap;
}

.heading__link::after {
  content: "";
  position: absolute;
  top: 49%;
  right: 0;
  transform: translateY(-50%);
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 6px;
  border-color: transparent transparent transparent var(--decor-color);
}

.heading__link:hover {
  --heading-color: var(--decor-color);
}

.allert-message {
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: 50px;
  padding: 10px 15px;
  font-weight: 700;
  background: var(--yellow);
  border-radius: 4px;
}

.allert-message--center {
  justify-content: center;
}

.allert-message__img {
  display: block;
  width: 40px;
  height: 40px;
}

.allert-message a {
  color: var(--decor-color);
  text-decoration: underline;
}

.allert-message a:hover {
  text-decoration: none;
}

.game-grid {
  --min-width: 160px;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(var(--min-width), 1fr));
  gap: 10px;
}

.game-grid--short > *:nth-child(n+3) {
  display: none;
  visibility: hidden;
}

.game-grid--small > *:nth-child(n+5) {
  display: none;
  visibility: hidden;
}

.game-grid > * {
  justify-self: center;
}

.game-grid + .heading {
  margin-top: 10px;
}

.shape {
  --shape-bg: var(--white);
  --shape-border: var(--white-dark);
  display: inline-block;
  padding: 7px;
  width: auto;
}

.shape--img {
  padding-top: 65px;
}

.shape--green {
  --shape-border: #41ae58;
}

.shape--yellow {
  --shape-border: var(--yellow);
}

.shape__close {
  position: absolute;
  top: -22px;
  right: -22px;
  z-index: 2;
}

.shape__body {
  position: relative;
  display: inline-flex;
  padding: 15px;
  width: auto;
  height: auto;
  background: var(--shape-bg);
  border: 10px solid var(--shape-border);
  border-radius: 20px;
  box-shadow: 0px 0px 0px 7px var(--shape-bg);
}

.tab {
  display: block;
  width: 100%;
}

.tab__menu {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0 auto 15px;
  padding: 0 10px;
  max-width: 1920px;
  width: 100%;
}

.tab__menu--center {
  justify-content: center;
  flex-wrap: wrap;
}

.tab__menu--center .tab__menu-item {
  flex-grow: 1;
}

.tab__menu--center .tab__menu-btn {
  width: 100%;
}

.tab__menu--right {
  justify-content: flex-end;
}

.tab__menu--payment {
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding: 0;
}

.tab__menu--payment .tab__menu-item {
  flex-grow: 1;
}

.tab__menu--payment .tab__menu-btn {
  width: 100%;
}

.tab__menu-item {
  cursor: pointer;
}

.tab__menu-item:hover .h2 {
  color: var(--gray);
}

.tab__menu-item.tab__menu-item--active .tab__step {
  color: var(--white);
  background: var(--gray-dark);
}

.tab__menu-item.tab__menu-item--active .tab__step .tab__icon {
  fill: var(--white);
}

.tab__menu-item.tab__menu-item--active .tab__menu-btn {
  --btn-font-color: var(--white-dark);
  --btn-bg-color: var(--gray-dark);
}

.tab__menu-item.tab__menu-item--active .h2 {
  color: var(--white);
}

.tab__menu-item .h2 {
  color: var(--decor-color);
  padding-right: 15px;
}

.tab__menu-btn {
  --btn-font-color: var(--font-color);
  --btn-bg-color: var(--white-dark);
  --btn-shadow-top: var(--decor-color);
  --btn-shadow-bottom: var(--red-2);
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  width: auto;
  height: 40px;
  font-size: 10px;
  color: var(--btn-font-color);
  background: var(--btn-bg-color);
  cursor: pointer;
}

.tab__menu-btn:hover {
  --btn-font-color: var(--white-dark);
  --btn-bg-color: var(--gray);
}

.tab__step {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  min-width: 30px;
  height: 30px;
  font-size: 14px;
  font-weight: 700;
  color: var(--gray);
  border-radius: 50%;
  background: var(--white);
  cursor: pointer;
}

.tab__img {
  display: block;
  width: 20px;
  height: 20px;
}

.tab__icon {
  fill: var(--gray);
}

.tab__menu-decor {
  flex: 1;
  display: block;
  height: 10px;
  border-bottom: 10px dotted var(--white-dark);
  pointer-events: none;
}

.table-container {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.table {
  --table-head-color: var(--gray);
  --table-color: var(--font-color);
  --table-bg: var(--white);
  border-collapse: collapse;
  margin-bottom: 20px;
  width: 100%;
}

.table thead tr td,
.table thead tr th {
  color: var(--table-head-color);
  text-transform: uppercase;
  background: var(--table-bg);
}

.table tr td,
.table tr th {
  padding: 5px 10px;
  height: 30px;
  white-space: nowrap;
  color: var(--table-color);
  border-bottom: 1px solid var(--black);
}

.table tr td.decor-bg,
.table tr th.decor-bg {
  background: var(--white);
}

.table a {
  color: var(--decor-color);
  text-decoration: underline;
}

.table a:hover {
  text-decoration: none;
}

.table__sort {
  position: relative;
  display: inline-block;
  padding-right: 15px;
  cursor: pointer;
  text-transform: lowercase;
}

.table__sort::first-letter {
  text-transform: uppercase;
}

.table__sort::before,
.table__sort::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border-style: solid;
}

.table__sort::before {
  border-width: 0 4px 4px 4px;
  border-color: transparent transparent var(--table-head-color) transparent;
  transform: translateY(-125%);
}

.table__sort::after {
  border-width: 4px 4px 0 4px;
  border-color: var(--table-head-color) transparent transparent transparent;
  transform: translateY(25%);
}

.table__cancel {
  opacity: 0.6;
  color: var(--gray);
}

.table__reject {
  color: var(--red);
}

.table__img-box {
  display: block;
  cursor: pointer;
}

.table__img {
  display: block;
  width: 20px;
  height: 20px;
}

.iframe-box {
  position: relative;
  display: block;
  min-width: 100%;
  min-height: 100vh;
}

.iframe-box iframe {
  min-height: 100vh;
}

ul.dot-list {
  padding-left: 10px;
}

ul.dot-list li {
  margin-bottom: 0;
}

ul.dot-list li::before {
  content: "";
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 1px;
  width: 5px;
  height: 5px;
  background: var(--decor-color);
  border-radius: 50%;
}

.sport-placeholder {
  --padding-title: 20px;
  position: relative;
  display: block;
  width: 100%;
  min-height: 100vh;
  background: var(--bg-color) url(../assets/img/sport/WC_404_Sport.jpg) center center/cover no-repeat;
}

.sport-placeholder__title {
  position: relative;
  position: relative;
  display: block;
  max-width: 1160px;
  margin: 0 auto;
}

.sport-placeholder__title::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  width: 50%;
  height: 0px;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: var(--decor-color) transparent transparent transparent;
}

.sport-placeholder__title-body {
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 0 var(--padding-title) 30px;
  width: 100%;
  background: #150d1f;
  -webkit-clip-path: polygon(0 0, 100% 0, calc(100% - var(--padding-title)) 100%, calc(0.00001% + var(--padding-title)) 100%);
          clip-path: polygon(0 0, 100% 0, calc(100% - var(--padding-title)) 100%, calc(0.00001% + var(--padding-title)) 100%);
}

.sport-placeholder__title-content {
  position: relative;
  display: block;
  padding: 30px var(--padding-title);
  width: 100%;
  font-size: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  background: var(--white);
  -webkit-clip-path: polygon(0 0, 100% 0, calc(100% - var(--padding-title)) 100%, calc(0.00001% + var(--padding-title)) 100%);
          clip-path: polygon(0 0, 100% 0, calc(100% - var(--padding-title)) 100%, calc(0.00001% + var(--padding-title)) 100%);
}

.sport-placeholder__title-content::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 80%;
  height: 0px;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #150d1f transparent transparent transparent;
}

.sport-placeholder__info {
  display: block;
  margin: 0 auto;
  padding: 0 15px;
  max-width: 650px;
}

@media screen and (min-width: 330px) {
  .bonus-card {
    max-width: 320px;
  }

  .bonus-card__img-box {
    width: 310px;
  }

  .tournament-card {
    max-width: 320px;
  }

  .tournament-card--vertical {
    display: block;
    max-width: 460px;
  }

  .tournament-card__img-box {
    width: 310px;
  }

  .testimonials__item {
    width: 320px;
  }

  .news__item {
    width: 320px;
  }
}

@media screen and (min-width: 340px) {
  .spin-wheel {
    --main-size: 300px;
  }

  .spin-wheel__container {
    padding: 0 10px;
  }

  .promo-slider .slick-list {
    -webkit-mask-image: linear-gradient(90deg, #fff, #fff 2%, #fff 97%, hsla(0deg, 0%, 100%, 0));
            mask-image: linear-gradient(90deg, #fff, #fff 2%, #fff 97%, hsla(0deg, 0%, 100%, 0));
  }
}

@media screen and (min-width: 350px) {
  .testimonials__slider .slick-list {
    -webkit-mask-image: linear-gradient(90deg, #fff, #fff 2%, #fff 97%, hsla(0deg, 0%, 100%, 0));
            mask-image: linear-gradient(90deg, #fff, #fff 2%, #fff 97%, hsla(0deg, 0%, 100%, 0));
  }

  .news__slider .slick-list {
    -webkit-mask-image: linear-gradient(90deg, #fff, #fff 2%, #fff 97%, hsla(0deg, 0%, 100%, 0));
            mask-image: linear-gradient(90deg, #fff, #fff 2%, #fff 97%, hsla(0deg, 0%, 100%, 0));
  }

  .game-grid--short > *:nth-child(n+3) {
    display: inline-block;
    visibility: visible;
  }

  .game-grid--short > *:nth-child(n+5) {
    display: none;
    visibility: hidden;
  }
}

@media screen and (min-width: 360px) {
  .registration-popup__info-box {
    padding: 0 40px 10px;
  }

  .registration-popup__item {
    padding: 0 40px;
  }

  .registration-popup__item--no-padding {
    padding: 0;
  }

  .registration-popup__form-grid {
    padding: 10px 40px;
  }

  .currency-current-trigger {
    margin-right: 10px;
  }

  .currency-current-trigger::after {
    right: 10px;
  }

  .currency-current-trigger .currency-item {
    max-width: 102px;
  }

  .spin-wheel {
    --main-size: 320px;
  }

  .header .lang {
    display: flex;
    visibility: visible;
  }

  .mobile-menu .registration-link {
    display: inline-flex;
    visibility: visible;
  }

  .fast-dep__form {
    padding: 20px 30px 25px;
  }

  .user-menu__nav {
    padding: 20px 20px 30px;
  }

  .user-menu__row {
    gap: 20px;
  }

  .user-menu .user__status-box {
    padding: 0 10px;
  }

  .user-menu .user__value-item {
    font-size: 14px;
  }

  .tab__menu-btn {
    font-size: 12px;
  }
}

@media screen and (min-width: 380px) {
  .spin-wheel {
    --main-size: 340px;
  }
}

@media screen and (min-width: 429px) {
  .header .user__short-info {
    display: inline-flex;
    visibility: visible;
    padding-right: 6px;
  }

  .header .user__img-area {
    margin-left: -10px;
  }
}

@media screen and (min-width: 430px) {
  .e-sports__item {
    width: 210px;
  }
}

@media screen and (min-width: 440px) {
  .popup__content {
    padding: 0 40px;
    min-width: 420px;
  }

  .popup__content--no-padding {
    padding: 0;
  }

  .spin-wheel {
    --main-size: 400px;
  }
}

@media screen and (min-width: 500px) {
  .currency {
    width: 400px;
  }

  .spin-btn {
    visibility: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    border-radius: 50% 0 0 50%;
    cursor: pointer;
  }

  .spin-btn__img {
    position: relative;
    display: block;
    width: 30px;
    min-width: 30px;
    height: 30px;
    z-index: 2;
  }

  .spin-btn__info {
    display: none;
    visibility: hidden;
  }

  .header__item {
    gap: 10px;
  }

  .logo--fixed {
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  .registration__row {
    gap: 10px;
  }
}

@media screen and (min-width: 520px) {
  .game-grid--short > *:nth-child(n+5) {
    display: inline-block;
    visibility: visible;
  }

  .game-grid--short > *:nth-child(n+7) {
    display: none;
    visibility: hidden;
  }

  .game-grid--small > *:nth-child(n+3) {
    display: inline-block;
    visibility: visible;
  }

  .game-grid--small > *:nth-child(n+4) {
    display: none;
    visibility: hidden;
  }
}

@media screen and (min-width: 550px) {
  .tournament__table-row {
    display: flex;
    gap: 10px;
  }

  .tournament__table-row > * {
    flex: 1;
  }
}

@media screen and (min-width: 600px) {
  .small-btn.btn-chat {
    position: fixed;
    bottom: 0;
    border-radius: 4px 0 0 0;
  }

  .spin-wheel {
    --main-size: 400px;
  }

  .spin-wheel__container {
    padding: 0 40px;
  }

  .hero {
    bottom: 0;
  }

  .action-area {
    display: none;
    visibility: hidden;
  }

  .header__deposit-link {
    display: inline-flex;
    visibility: visible;
  }

  .header .registration-link {
    display: inline-flex;
    visibility: visible;
  }

  .header .user__short-info {
    padding-right: 15px;
  }

  .header .user__value-side {
    display: block;
  }

  .search-widget-trigger--big {
    padding: 0 3px;
    width: auto;
    border-radius: 4px;
  }

  .search-widget-trigger--big .search-widget-trigger__info {
    display: block;
    visibility: visible;
    padding: 0 6px;
    font-size: 12px;
    font-weight: 700;
    color: var(--white-dark);
    white-space: nowrap;
  }

  .fast-dep__form {
    max-width: 420px;
    padding: 25px 50px 30px;
  }

  .bonus-info__proposition-box {
    padding-top: 30px;
  }

  .bonus-info__proposition-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }

  .tournament {
    margin: 0 auto 20px;
  }

  .payment-card {
    padding: 5px;
  }

  .rules {
    margin: 0;
  }

  .rules__info--faq {
    padding-left: 30px;
  }

  .rules__content > li {
    padding-top: calc(var(--header-height) + 32px);
  }

  .rules__content > li::before {
    top: calc(var(--header-height) + 32px);
  }

  .rules__content > li::after {
    content: "";
    display: block;
    position: absolute;
    top: calc(var(--header-height) + 16px);
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--font-color);
  }

  .account__grid {
    margin: 0 auto;
  }

  .account__body {
    padding: 20px 40px;
  }

  .footer {
    --footer-padding-bottom: 0;
  }
}

@media screen and (min-width: 690px) {
  .game-grid--short > *:nth-child(n+7) {
    display: inline-block;
    visibility: visible;
  }

  .game-grid--short > *:nth-child(n+9) {
    display: none;
    visibility: hidden;
  }

  .game-grid--small > *:nth-child(n+4) {
    display: inline-block;
    visibility: visible;
  }

  .game-grid--small > *:nth-child(n+5) {
    display: none;
    visibility: hidden;
  }

  .sport-placeholder {
    --padding-title: 60px;
  }

  .sport-placeholder__title-body {
    padding: 0 20px 30px;
  }

  .sport-placeholder__title-content {
    font-size: 32px;
  }
}

@media screen and (min-width: 760px) {
  .registration-popup__deposit-box {
    max-width: 490px;
  }

  .registration-popup__deposit-box .form-container {
    margin: 0 auto;
  }

  .login__row .bonus-link {
    display: flex;
    visibility: visible;
  }

  .main-slider__item {
    flex-direction: row-reverse;
    gap: 10px;
  }

  .main-slider__img-side {
    flex: 1;
  }

  .main-slider__info-side {
    flex: 1;
    margin-top: 0;
    padding-top: 30px;
  }

  .bonus-card {
    display: flex;
    max-width: 630px;
  }

  .bonus-card--vertical {
    display: block;
    max-width: 320px;
  }

  .bonus-card--vertical .bonus-card__img-box {
    width: 310px;
  }

  .bonus-card > * {
    flex: 1;
  }

  .bonus-card__img-box {
    width: 310px;
    min-width: 310px;
    height: 210px;
    padding-bottom: 0;
  }

  .bonus-card__content {
    width: 310px;
    min-width: 310px;
  }

  .bonus-page__grid {
    --min-width: 630px;
    grid-template-columns: repeat(auto-fit, var(--min-width));
  }

  .tournament-card {
    display: flex;
    max-width: 630px;
  }

  .tournament-card--vertical {
    display: block;
    max-width: 460px;
  }

  .tournament-card > * {
    flex: 1;
  }

  .tournament-card__img-box {
    width: 310px;
    min-width: 310px;
    height: 210px;
    padding-bottom: 0;
  }

  .tournament-card__content {
    width: 310px;
    min-width: 310px;
  }

  .tournament__grid {
    grid-template-columns: auto 1fr;
    gap: 20px;
  }

  .tournament__table-box {
    grid-area: 2/1/3/-1;
  }

  .payment-catalog__grid {
    grid-template-columns: repeat(auto-fill, 160px);
  }

  .account__filter-item {
    width: auto;
  }

  .account__filter-item .select--big {
    min-width: 180px;
  }

  .account__form-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
  }

  .account__form-row .btn {
    margin-top: 26px;
    height: 35px;
  }

  .tab__menu--center {
    gap: 10px 20px;
  }

  .tab__menu--center .tab__menu-item {
    flex-grow: unset;
  }

  .tab__menu--center .tab__menu-btn {
    width: auto;
  }

  .tab__menu--payment {
    justify-content: flex-start;
  }

  .tab__menu--payment .tab__menu-item {
    flex-grow: unset;
  }

  .tab__menu--payment .tab__menu-btn {
    width: auto;
  }

  .tab__menu-btn {
    padding: 0 15px;
    min-width: 160px;
    font-size: 14px;
  }
}

@media screen and (min-width: 860px) {
  .game-grid--short > *:nth-child(n+9) {
    display: inline-block;
    visibility: visible;
  }

  .game-grid--short > *:nth-child(n+11) {
    display: none;
    visibility: hidden;
  }

  .game-grid--small > *:nth-child(n+5) {
    display: inline-block;
    visibility: visible;
  }

  .game-grid--small > *:nth-child(n+6) {
    display: none;
    visibility: hidden;
  }
}

@media screen and (min-width: 900px) {
  .registration-popup__row {
    display: flex;
    justify-content: center;
    padding: 0 20px;
  }

  .registration-popup__item {
    padding: 0 20px;
    width: 410px;
  }

  .registration-popup__item--no-padding {
    padding: 0;
  }

  .registration-popup__alert-box {
    display: flex;
    align-items: flex-end;
  }

  .registration-popup__deposit-box {
    max-width: 860px;
  }

  .registration-popup__deposit-box .tab__menu--payment {
    justify-content: center;
  }

  .registration-popup__deposit-box .btn-row {
    padding: 26px 0 0;
  }

  .registration-popup__deposit-box .btn {
    height: 35px;
  }

  .registration-popup__deposit-box .table {
    margin-top: 15px;
  }

  .registration-popup__form-grid {
    display: grid;
    grid-template-columns: minmax(300px, 400px) minmax(300px, 400px) auto;
    justify-content: center;
    gap: 0 10px;
    padding-top: 20px;
  }

  .registration-popup__form-grid > * {
    order: 9;
  }

  .registration-popup__form-grid-order-1 {
    order: 1;
    grid-column-start: 1;
    grid-column-end: -1;
  }

  .registration-popup__form-grid-order-2 {
    order: 2;
  }

  .registration-popup__form-grid-order-3 {
    order: 3;
  }

  .registration-popup__form-grid-order-4 {
    order: 4;
  }

  .registration-popup__form-grid-order-5 {
    order: 5;
  }

  .registration-popup__form-grid-order-6 {
    order: 6;
  }

  .tab__menu-btn {
    min-width: 200px;
  }

  .sport-placeholder {
    --padding-title: 120px;
  }

  .sport-placeholder__title-content {
    -webkit-clip-path: polygon(0 0, 100% 0, calc(100% - var(--padding-title) + 20px) 100%, calc(0.00001% + var(--padding-title) - 20px) 100%);
            clip-path: polygon(0 0, 100% 0, calc(100% - var(--padding-title) + 20px) 100%, calc(0.00001% + var(--padding-title) - 20px) 100%);
  }
}

@media screen and (min-width: 990px) {
  .main-slider__item {
    padding-left: 25px;
    padding-right: 25px;
    gap: 20px;
  }
}

@media screen and (min-width: 1030px) {
  .game-grid--short > *:nth-child(n+11) {
    display: inline-block;
    visibility: visible;
  }

  .game-grid--short > *:nth-child(n+13) {
    display: none;
    visibility: hidden;
  }

  .game-grid--small > *:nth-child(n+6) {
    display: inline-block;
    visibility: visible;
  }

  .game-grid--small > *:nth-child(n+7) {
    display: none;
    visibility: hidden;
  }
}

@media screen and (min-width: 1100px) {
  .account__form-grid {
    display: grid;
    grid-template-columns: minmax(300px, 400px) minmax(300px, 400px) auto;
    justify-content: center;
    gap: 0 20px;
  }

  .account__form-grid > * {
    order: 9;
  }

  .account__form-grid-order-1 {
    order: 1;
    grid-column-start: 1;
    grid-column-end: -1;
  }

  .account__form-grid-order-2 {
    order: 2;
  }

  .account__form-grid-order-3 {
    order: 3;
  }

  .account__form-grid-order-4 {
    order: 4;
  }

  .account__form-grid-order-5 {
    order: 5;
  }

  .account__form-grid-order-6 {
    order: 6;
  }

  .account__form-grid .btn-row {
    padding: 26px 0 0;
  }

  .account__form-grid .btn {
    height: 35px;
  }

  .account__form-grid .table {
    margin-top: 15px;
  }

  .account__filter-row {
    justify-content: flex-start;
  }

  .account__filter-item:last-child {
    margin-left: auto;
  }

  .account__messaage {
    padding: 20px 40px 30px;
  }

  .account__messaage-date {
    padding-right: 0;
  }
}

@media screen and (min-width: 1200px) {
  .header__item--left {
    padding-right: 10px;
  }

  .header__item--right {
    padding-left: 10px;
  }

  .header .hamburger {
    display: none;
    visibility: hidden;
  }

  .header .main-nav {
    display: flex;
    visibility: visible;
  }

  .logo--fixed {
    position: static;
    top: unset;
    left: unset;
    transform: translateX(0);
  }

  .main-slider__item {
    padding-left: 40px;
    padding-right: 40px;
  }

  .main-slider__info-box {
    padding: 35px 20px;
  }

  .game-item:hover .game-item__btn-box {
    opacity: 1;
    pointer-events: all;
  }

  .game-item__widget-trigger {
    display: none;
    visibility: hidden;
  }

  .game-item__img-box:after {
    font-size: 24px;
  }

  .bonus-info__title-box {
    padding: 20px;
  }

  .tournament__grid {
    grid-template-columns: auto 1fr auto;
    align-items: start;
    gap: 40px;
    padding: 0 40px;
  }

  .tournament__table-box {
    grid-area: unset;
    margin-bottom: 0;
    max-width: 600px;
  }

  .tournament__info-box {
    display: block;
    max-width: 330px;
  }

  .rules {
    font-size: 14px;
  }

  .rules--big-font {
    font-size: 16px;
  }

  .rules__nav {
    font-size: 14px;
    font-weight: 700;
    color: var(--font-color);
  }

  .rules__nav li::before {
    width: 30px;
  }

  .rules__nav li a {
    text-decoration: none;
  }

  .rules__nav li a:hover {
    color: var(--decor-color);
  }

  ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }

  .game-grid {
    --min-width: 200px;
  }

  .game-grid--short > *:nth-child(n+11) {
    display: none;
    visibility: hidden;
  }

  .game-grid--small > *:nth-child(n+6) {
    display: none;
    visibility: hidden;
  }
}

@media screen and (min-width: 1270px) {
  .game-grid--short > *:nth-child(n+11) {
    display: inline-block;
    visibility: visible;
  }

  .game-grid--short > *:nth-child(n+13) {
    display: none;
    visibility: hidden;
  }

  .game-grid--small > *:nth-child(n+6) {
    display: inline-block;
    visibility: visible;
  }

  .game-grid--small > *:nth-child(n+7) {
    display: none;
    visibility: hidden;
  }
}

@media screen and (min-width: 1300px) {
  .them-catalog__slider .slick-list {
    -webkit-mask-image: linear-gradient(90deg, hsla(0deg, 0%, 100%, 0), #fff 3%, #fff 97%, hsla(0deg, 0%, 100%, 0));
            mask-image: linear-gradient(90deg, hsla(0deg, 0%, 100%, 0), #fff 3%, #fff 97%, hsla(0deg, 0%, 100%, 0));
  }

  .provider-catalog__slider .slick-list {
    -webkit-mask-image: linear-gradient(90deg, hsla(0deg, 0%, 100%, 0), #fff 3%, #fff 97%, hsla(0deg, 0%, 100%, 0));
            mask-image: linear-gradient(90deg, hsla(0deg, 0%, 100%, 0), #fff 3%, #fff 97%, hsla(0deg, 0%, 100%, 0));
  }

  .payment-system__slider .slick-list {
    -webkit-mask-image: linear-gradient(90deg, hsla(0deg, 0%, 100%, 0), #fff 3%, #fff 97%, hsla(0deg, 0%, 100%, 0));
            mask-image: linear-gradient(90deg, hsla(0deg, 0%, 100%, 0), #fff 3%, #fff 97%, hsla(0deg, 0%, 100%, 0));
  }

  .tournament__table-row {
    gap: 30px;
  }

  .footer__nav {
    display: block;
    visibility: visible;
  }
}

@media screen and (min-width: 1400px) {
  .bonus-link--active .bonus-link__info,
  .bonus-link:hover .bonus-link__info {
    color: var(--gray-dark);
    background: var(--white-dark);
  }

  .bonus-link__info {
    display: flex;
    align-items: center;
    visibility: visible;
    margin-left: -5px;
    padding: 0 5px 0 10px;
    height: 20px;
    font-size: 10px;
    font-weight: 700;
    white-space: nowrap;
    color: var(--gray);
    text-transform: uppercase;
    border-radius: 0 4px 4px 0;
  }

  .spin-btn:hover .spin-btn__info {
    color: var(--gray-dark);
    background: var(--white-dark);
  }

  .spin-btn__info {
    display: flex;
    align-items: center;
    visibility: visible;
    margin-left: -5px;
    padding: 0 5px 0 10px;
    height: 20px;
    font-size: 10px;
    font-weight: 700;
    white-space: nowrap;
    color: var(--gray);
    text-transform: uppercase;
    border-radius: 0 4px 4px 0;
  }

  .game-grid {
    --min-width: 250px;
  }

  .game-grid--short > *:nth-child(n+11) {
    display: none;
    visibility: hidden;
  }

  .game-grid--small > *:nth-child(n+6) {
    display: none;
    visibility: hidden;
  }

  .sport-placeholder {
    --padding-title: 145px;
  }

  .sport-placeholder__title-content {
    -webkit-clip-path: polygon(0 0, 100% 0, calc(100% - var(--padding-title) + 25px) 100%, calc(0.00001% + var(--padding-title) - 25px) 100%);
            clip-path: polygon(0 0, 100% 0, calc(100% - var(--padding-title) + 25px) 100%, calc(0.00001% + var(--padding-title) - 25px) 100%);
  }
}

@media screen and (min-width: 1570px) {
  .game-grid--short > *:nth-child(n+11) {
    display: inline-block;
    visibility: visible;
  }

  .game-grid--short > *:nth-child(n+13) {
    display: none;
    visibility: hidden;
  }

  .game-grid--small > *:nth-child(n+6) {
    display: inline-block;
    visibility: visible;
  }

  .game-grid--small > *:nth-child(n+7) {
    display: none;
    visibility: hidden;
  }
}

@media screen and (min-width: 1600px) {
  .them-catalog__slider .slick-list {
    -webkit-mask-image: linear-gradient(90deg, hsla(0deg, 0%, 100%, 0), #fff 5%, #fff 95%, hsla(0deg, 0%, 100%, 0));
            mask-image: linear-gradient(90deg, hsla(0deg, 0%, 100%, 0), #fff 5%, #fff 95%, hsla(0deg, 0%, 100%, 0));
  }

  .provider-catalog__slider .slick-list {
    -webkit-mask-image: linear-gradient(90deg, hsla(0deg, 0%, 100%, 0), #fff 5%, #fff 95%, hsla(0deg, 0%, 100%, 0));
            mask-image: linear-gradient(90deg, hsla(0deg, 0%, 100%, 0), #fff 5%, #fff 95%, hsla(0deg, 0%, 100%, 0));
  }

  .payment-system__slider .slick-list {
    -webkit-mask-image: linear-gradient(90deg, hsla(0deg, 0%, 100%, 0), #fff 5%, #fff 95%, hsla(0deg, 0%, 100%, 0));
            mask-image: linear-gradient(90deg, hsla(0deg, 0%, 100%, 0), #fff 5%, #fff 95%, hsla(0deg, 0%, 100%, 0));
  }

  body {
    font-size: 14px;
  }
}

@media screen and (min-width: 1800px) {
  :root {
    --header-height: 100px;
  }

  .small-btn {
    padding: 0 25px;
    height: 40px;
    font-size: 14px;
  }

  .small-btn.btn-chat {
    min-width: 210px;
  }

  .small-btn.btn-chat .small-btn__img {
    display: block;
    width: 40px;
    height: 40px;
  }

  .btn {
    padding: 0 35px;
    height: 50px;
    font-size: 16px;
  }

  .btn--medium {
    min-width: 200px;
  }

  .action-btn {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }

  .bonus-link__img {
    width: 40px;
    min-width: 40px;
    height: 40px;
  }

  .bonus-link__info {
    padding: 0 10px;
    height: 30px;
    font-size: 14px;
  }

  .btn-row {
    gap: 20px;
    padding: 20px 0;
  }

  .input-area {
    height: 50px;
  }

  .input-area--submit {
    margin-bottom: 15px;
  }

  .input-area--copy .input-area__placeholder {
    padding-right: 50px;
  }

  .input-area::after {
    font-size: 14px;
  }

  .input-area__img-box {
    width: 50px;
    height: 50px;
  }

  .hide-input-area__trigger {
    font-size: 12px;
  }

  .hide-input-area.hide-input-area--button .hide-input-area__trigger {
    height: 40px;
  }

  .checkbox {
    margin-top: 20px;
  }

  .checkbox__square {
    margin-right: 10px;
    min-width: 30px;
    width: 30px;
    min-height: 30px;
    height: 30px;
  }

  .checkbox__square:after {
    content: "";
    width: 14px;
    height: 9px;
    border-left: 4px solid var(--gray-dark);
    border-bottom: 4px solid var(--gray-dark);
  }

  .checkbox__label-content {
    font-size: 16px;
  }

  .social__info {
    font-size: 12px;
  }

  .select {
    height: 50px;
    min-height: 50px;
  }

  .registration-popup {
    max-width: 1260px;
    width: 1260px;
  }

  .registration-popup__deposit-box {
    max-width: 1260px;
  }

  .registration-popup__deposit-box .btn {
    height: 50px;
  }

  .registration-popup__form-grid {
    grid-template-columns: 400px 400px auto;
    gap: 0 40px;
  }

  .spin-btn__img {
    width: 40px;
    min-width: 40px;
    height: 40px;
  }

  .spin-btn__info {
    padding: 0 10px;
    height: 30px;
    font-size: 14px;
  }

  .header__top {
    height: 60px;
  }

  .header__row {
    padding: 0 20px;
    height: 60px;
  }

  .header__center {
    height: 40px;
  }

  .header .small-btn {
    font-size: 16px;
  }

  .logo {
    padding: 0 20px 0 0;
  }

  .logo__img {
    width: 110px;
    height: 60px;
  }

  .search-widget-trigger--big {
    height: 40px;
    padding: 0 10px;
  }

  .search-widget-trigger--big .search-widget-trigger__info {
    padding: 0 15px;
    font-size: 16px;
  }

  .lang__current {
    width: 40px;
    height: 40px;
  }

  .lang__current .lang__img {
    width: 40px;
    height: 40px;
  }

  .user__short-info {
    min-height: 42px;
    height: 42px;
  }

  .user__short-info-icon {
    width: 30px;
    height: 30px;
    font-size: 18px;
  }

  .user__value-item {
    font-size: 18px;
  }

  .user__img-box {
    width: 42px;
    min-width: 42px;
    height: 42px;
  }

  .user__img {
    width: 36px;
    min-width: 36px;
    height: 36px;
  }

  .user__alert {
    transform: translate(50%, -7px);
    width: 20px;
    height: 20px;
    font-size: 12px;
  }

  .search-widget {
    grid-template-rows: 60px 1fr;
    max-width: 440px;
  }

  .search-widget__action-area {
    height: 60px;
  }

  .search-result__img-link {
    display: block;
    width: 145px;
    min-width: 145px;
  }

  .search-result__title {
    max-width: 119px;
    font-size: 16px;
  }

  .search-result__sub-title {
    max-width: 119px;
    font-size: 12px;
  }

  .search-result__btn {
    padding: 0 15px;
    height: 30px;
    font-size: 12px;
  }

  .fast-dep__title {
    padding: 20px;
  }

  .fast-dep__form {
    max-width: 500px;
  }

  .user-menu {
    grid-template-rows: 60px 1fr 70px;
  }

  .user-menu__link {
    height: 40px;
    font-size: 14px;
    font-weight: 700;
  }

  .user-menu .hamburger {
    width: 40px;
    min-width: 40px;
    height: 40px;
  }

  .main-nav__item {
    height: 60px;
  }

  .main-nav__link {
    padding: 0 20px;
    height: 30px;
    font-size: 14px;
    font-weight: 700;
  }

  .them-filter__link {
    height: 40px;
  }

  .them-filter__link--active .them-filter__link-info {
    background: var(--black-dark);
  }

  .them-filter__link--active:hover .them-filter__link-info {
    background: var(--black-dark);
  }

  .them-filter__link--sub-menu .them-filter__link-info {
    padding-right: 45px;
  }

  .them-filter__link--sub-menu .them-filter__link-info::before {
    right: 18px;
    width: 20px;
    height: 20px;
  }

  .them-filter__link--sub-menu .them-filter__link-info::after {
    right: 23px;
    border-width: 5px 5px 0 5px;
  }

  .them-filter__link--sub-menu:hover .them-filter__link-info,
  .them-filter__link--sub-menu.them-filter__link--active .them-filter__link-info {
    padding-right: 45px;
  }

  .them-filter__link-info {
    padding: 0 20px;
    height: 30px;
    font-size: 14px;
    font-weight: 700;
  }

  .them-catalog {
    padding: 30px 0;
  }

  .them-catalog__list {
    grid-template-columns: repeat(auto-fill, 140px);
    gap: 20px;
  }

  .them-catalog__link {
    width: 140px;
  }

  .them-catalog__link-img {
    width: 130px;
    height: 100px;
  }

  .them-catalog__link-info {
    width: 130px;
    height: 35px;
    font-size: 12px;
  }

  .them-catalog__link-info span {
    width: 130px;
  }

  .provider-catalog__item {
    padding-top: 6px;
  }

  .provider-catalog__link {
    height: 50px;
  }

  .provider-catalog__link-img-box {
    width: 40px;
    height: 40px;
  }

  .provider-catalog__link-info {
    font-size: 12px;
  }

  .provider-catalog__link-count {
    top: -6px;
    left: 35px;
    min-width: 26px;
    height: 16px;
    font-size: 12px;
  }

  .game-item__btn {
    width: 60px;
    height: 40px;
  }

  .game-item__jackpot-info {
    min-width: 180px;
    height: 30px;
    font-size: 14px;
  }

  .game-item__title {
    font-size: 12px;
  }

  .play-game__header {
    height: 60px;
  }

  .play-game__title-img {
    width: 40px;
    min-width: 40px;
    height: 40px;
  }

  .play-game__action-box {
    gap: 10px;
  }

  .play-game__game-box {
    height: calc(100% - 60px);
  }

  .win-now__item {
    font-size: 12px;
    line-height: 1.4;
  }

  .win-now__value {
    padding: 0 15px;
    height: 30px;
    font-size: 14px;
  }

  .bonus-card--vertical {
    display: block;
    padding: 0 10px;
    max-width: 480px;
  }

  .bonus-card--vertical .bonus-card__img-box {
    padding-bottom: 67.74193%;
    width: 460px;
    height: 0;
  }

  .bonus-card--vertical .bonus-card__content {
    width: 100%;
  }

  .bonus-card__info-box {
    gap: 10px;
    font-size: 14px;
  }

  .bonus-info {
    max-width: 1236px;
    font-size: 14px;
  }

  .bonus-info__content {
    max-width: 940px;
  }

  .bonus-info__title-box {
    font-size: 22px;
  }

  .bonus-info__title--big {
    font-size: 3.33333em;
  }

  .bonus-page__grid {
    --min-width: 50%;
    gap: 20px 0;
    margin: 0 10px;
    grid-template-columns: repeat(auto-fit, var(--min-width));
  }

  .bonus-page .bonus-card {
    padding: 0 10px;
    max-width: 960px;
  }

  .bonus-page .bonus-card__img-box {
    width: 50%;
    height: 310px;
  }

  .bonus-page .bonus-card__content {
    grid-template-rows: 1fr 60px;
    padding: 20px 50px 20px;
  }

  .bonus-page .bonus-card__info-box {
    font-size: 14px;
  }

  .bonus-page .bonus-card__info--big {
    font-size: 1.571428em;
  }

  .tournament-card--vertical {
    display: block;
    width: 460px;
  }

  .tournament-card--vertical .tournament-card__img-box {
    padding-bottom: 67.74193%;
    height: 0;
  }

  .tournament-card--vertical .tournament-card__content {
    width: 100%;
  }

  .tournament-card--vertical .tournament-card__info--big {
    font-size: 1.58em;
  }

  .tournament-card__info-box {
    gap: 10px;
    font-size: 14px;
  }

  .payment-system__item {
    padding: 0 10px;
  }

  .payment-system__img-box {
    width: 140px;
    height: 50px;
  }

  .payment-system__img {
    max-width: 130px;
    max-height: 40px;
  }

  .testimonials__item {
    padding: 0px 7px;
    width: 474px;
  }

  .testimonials__info-box {
    height: 80px;
  }

  .testimonials__info {
    height: 53px;
    font-size: 14px;
  }

  .testimonials__author-box {
    height: 50px;
    font-size: 12px;
  }

  .testimonials__author-name {
    font-size: 16px;
  }

  .e-sports__item {
    width: 316px;
    padding: 0 8px;
  }

  .e-sports__info-box {
    padding-top: 20px;
    height: 160px;
    font-size: 14px;
  }

  .e-sports__time {
    margin-top: 15px;
    font-size: 16px;
  }

  .e-sports__date {
    font-size: 12px;
  }

  .betting-category__list {
    padding: 5px 50px;
  }

  .betting-category__link {
    min-width: 40px;
    height: 40px;
  }

  .news__item {
    padding: 0 7px;
    width: 474px;
  }

  .news__info-box {
    height: 130px;
  }

  .news__title-link {
    height: 40px;
    font-size: 16px;
  }

  .news__info {
    height: 50px;
    font-size: 14px;
  }

  .tournament {
    font-size: 14px;
  }

  .tournament__grid {
    gap: 60px;
  }

  .tournament__table-box {
    max-width: 700px;
  }

  .tournament__info-box {
    display: block;
    max-width: 460px;
  }

  .rules__inner {
    max-width: 1260px;
  }

  .rules__nav {
    font-size: 16px;
  }

  .rules ol > li::before {
    font-size: 16px;
  }

  .account__body {
    padding: 35px 40px;
  }

  .account__bonus-grid {
    grid-template-columns: repeat(auto-fit, 480px);
  }

  .account__shape--large {
    max-width: 1260px;
  }

  .account__form-row {
    gap: 20px;
  }

  .account .btn {
    height: 50px;
  }

  .footer__info-box {
    font-size: 12px;
  }

  .footer-nav__link {
    padding: 0 15px;
    min-height: 30px;
    font-size: 12px;
  }

  .footer-contacts__list {
    gap: 10px 50px;
  }

  .footer-contacts__link {
    font-size: 14px;
  }

  h1,
  .h1 {
    font-size: 30px;
  }

  h2,
  .h2 {
    font-size: 18px;
  }

  h3,
  .h3 {
    font-size: 16px;
  }

  .heading__link {
    font-size: 18px;
  }

  .allert-message {
    height: 60px;
  }

  .tab__step {
    width: 40px;
    min-width: 40px;
    height: 40px;
    font-size: 16px;
  }

  .table tr td,
  .table tr th {
    height: 40px;
  }
}

@media screen and (min-width: 1820px) {
  .betting-event__title {
    height: 40px;
  }

  .betting-event__item {
    width: 316px;
    padding: 0 8px;
  }

  .betting-event__player-img-box {
    width: 80px;
    height: 80px;
  }

  .betting-event__player-img {
    max-width: 60px;
    max-height: 70px;
  }

  .betting-event__player-name {
    height: 30px;
    font-size: 14px;
  }

  .betting-event__game-time {
    font-size: 16px;
  }

  .betting-event__game-date {
    font-size: 12px;
  }

  .betting-event__link {
    height: 50px;
    font-size: 12px;
    line-height: 14px;
  }

  .betting-event__link b {
    font-size: 16px;
  }
}

@media screen and (min-width: 1830px) {
  .game-grid--short > *:nth-child(n+14) {
    display: inline-block;
    visibility: visible;
  }

  .game-grid--short > *:nth-child(n+16) {
    display: none;
    visibility: hidden;
  }

  .game-grid--small > *:nth-child(n+7) {
    display: inline-block;
    visibility: visible;
  }

  .game-grid--small > *:nth-child(n+8) {
    display: none;
    visibility: hidden;
  }
}

@media screen and (min-width: 1900px) {
  .testimonials__slider .slick-list {
    -webkit-mask-image: none;
            mask-image: none;
  }

  .game-grid {
    --min-width: 300px;
  }

  .game-grid--short > *:nth-child(n+13) {
    display: none;
    visibility: hidden;
  }

  .game-grid--small > *:nth-child(n+7) {
    display: none;
    visibility: hidden;
  }
}

@media screen and (min-width: 1920px) {
  .promo-slider .slick-list {
    -webkit-mask-image: none;
            mask-image: none;
  }

  .e-sports__slider .slick-list {
    -webkit-mask-image: none;
            mask-image: none;
  }

  .betting-event__slider .slick-list {
    -webkit-mask-image: none;
            mask-image: none;
  }

  .news__slider .slick-list {
    -webkit-mask-image: none;
            mask-image: none;
  }

  .slick-prev,
  .slick-next {
    width: 40px;
    height: 40px;
  }

  .slick-prev::before,
  .slick-next::before {
    background: url(../assets/img/icons/icons.svg#ico_arrow) center center/30px 30px no-repeat;
  }

  .slick-next {
    right: 2px;
  }
}

[_tolgee] {
  pointer-events: all;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__tab-loop {
  position: absolute;
}

.react-datepicker-wrapper .react-datepicker__input-container .input-area {
  height: 35px;
  width: 100%;
  background: none;
  font-size: 16px;
  flex: 1 1;
  padding: 0 14px;
  outline: none;
  color: var(--input-color);
  background: var(--input-bg);
  border: 1px solid var(--input-decor-color);
}
.react-datepicker-wrapper .react-datepicker__input-container .input-area::placeholder {
  color: var(--input-color);
}

.input-area__label-datepicker {
  position: absolute;
  top: 12px;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  color: var(--gray);
}

.react-datepicker {
  display: flex!important;
}

.sumsub-iframe  {
  height: 100%;
}

.sumsub-iframe iframe {
  height: 100%!important;
}
